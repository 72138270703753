<template>
  <div class="StateConfig">
    <baseSection name="状态配置">
      <el-row>
        <el-button class="btn_table" icon="el-icon-edit" @click="StateEdit" v-if="isStateEdit">修改</el-button>
        <div v-else>
          <el-button class="btn_table iconfont icon-dcicon_save1" type="primary" @click="saveStateEdit">保存</el-button>
          <el-button class="btn_table iconfont icon-dcx" @click="StateQuitEdit">取消</el-button>
        </div>
      </el-row>
      <el-form ref="form" :model="form" label-width="110px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="独立故障" class="switch">
              <el-switch :disabled="isStateEdit" v-model="form.independentAlarmTopic" @change="changeSwitch"></el-switch>
            </el-form-item>
            <span class="hint">开启后，表明该设备使用独立的故障采集通道</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="采集频率"
              prop="scanInterval"
              :rules="[
                { required: true, message: '请输入采集频率', trigger: 'blur' },
                { type: 'number', message: '采集频率必须为数字值', trigger: 'blur' }
              ]"
            >
              <el-input v-model.number="form.scanInterval" class="scanInterval" :disabled="isStateEdit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="2" :span="6" v-if="form.independentAlarmTopic">
            <el-form-item
              label="故障扫描频率"
              prop="scanInterval"
              :rules="[
                { required: true, message: '请输入扫描间隔', trigger: 'blur' },
                { type: 'number', message: '扫描间隔必须为数字值', trigger: 'blur' }
              ]"
            >
              <el-input v-model.number="form.alarmScanInterval" class="scanInterval" :disabled="isStateEdit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="运行状态" :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
              <el-select v-model="form.runningCondition" placeholder="请选择" :disabled="isStateEdit" @change="changeSelect">
                <el-option :disabled="item.disabled" v-for="item in statesOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="2" :span="6" v-if="!form.independentAlarmTopic">
            <el-form-item label="故障状态" :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
              <el-select v-model="form.alarmCondition" placeholder="请选择" :disabled="isStateEdit" @change="changeSelect">
                <el-option :disabled="item.disabled" v-for="item in statesOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="2" :span="6" v-else>
            <el-form-item label="独立故障状态" :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
              <el-select v-model="alarmCondition" placeholder="请选择" :disabled="isStateEdit" @change="changeSelect">
                <el-option :disabled="item.disabled" v-for="item in AlarmStatesOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="2" :span="6">
            <el-form-item label="空闲状态" :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
              <el-select v-model="form.freeCondition" placeholder="请选择" :disabled="isStateEdit" @change="changeSelect">
                <el-option :disabled="item.disabled" v-for="item in statesOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </baseSection>
    <baseSection name="产量配置">
      <el-row>
        <el-button class="btn_table" icon="el-icon-edit" @click="editYieldEdit" v-if="isYieldEdit">修改</el-button>
        <div v-else>
          <el-button class="btn_table iconfont icon-dcicon_save1" type="primary" @click="saveYieldEdit">保存</el-button>
          <el-button class="btn_table iconfont icon-dcx" @click="quitYieldEdit">取消</el-button>
        </div>
      </el-row>
      <el-form label-width="100px">
        <el-row>
          <el-col :span="14">
            <el-form-item label="选择生产">
              <el-select v-model="paramId" multiple placeholder="请选择" :disabled="isYieldEdit">
                <el-option v-for="item in tagList" :key="item.paramId" :label="item.paramName" :value="item.paramId"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
export default {
  data() {
    return {
      isStateEdit: true,
      isYieldEdit: true,
      form: {
        scanInterval: 0,
        runningCondition: '',
        freeCondition: '',
        alarmCondition: '',
        offlineCondition: '',
        independentAlarmTopic: false,
        alarmScanInterval: ''
      },
      alarmCondition: '',
      statesOption: [
        {
          value: 'ORIGIN',
          label: '原始值'
        },
        {
          value: 'HIGH',
          label: '高电平'
        },
        {
          value: 'LOW',
          label: '低电平'
        },
        {
          value: 'PULSE',
          label: '脉冲信号'
        }
      ],
      AlarmStatesOption: [
        {
          value: 'ORIGIN',
          label: '原始值'
        },
        {
          value: 'HIGH',
          label: '高电平'
        },
        {
          value: 'LOW',
          label: '低电平'
        },
        {
          value: 'PULSE',
          label: '脉冲信号'
        }
      ],
      deviceId: '',
      tagList: [],
      paramId: []
    };
  },
  components: { baseSection },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.deviceId = this.$parent.deviceId;
      const data = {
        deviceId: this.deviceId
      };
      const res = await this.$apis.device.getParamsName(data);
      const yieldRes = await this.$apis.StateConfig.getYield(data);
      const StateRes = await this.$apis.StateConfig.getState(data);
      this.form = StateRes;
      this.alarmCondition = this.form.alarmCondition;

      this.paramId = [];
      yieldRes.forEach(item => {
        this.paramId.push(item.paramId);
      });
      this.tagList = res;
      this.changeSelect();
    },
    changeSwitch() {
      if (!this.form.independentAlarmTopic) {
        this.form.alarmCondition = this.alarmCondition;
      } else {
        this.alarmCondition = this.form.alarmCondition;
        this.form.alarmCondition = '';
      }
      this.changeSelect();
    },
    changeSelect() {
      this.statesOption.forEach(item => {
        if (item.value !== 'ORIGIN') {
          item.disabled = false;
        }
      });
      for (const i in this.form) {
        if (Object.hasOwnProperty.call(this.form, i)) {
          const element = this.form[i];
          for (let index = 0; index < this.statesOption.length; index++) {
            const item = this.statesOption[index];
            if (item.value !== 'ORIGIN' && item.value === element) {
              item.disabled = true;
            }
          }
        }
      }
    },
    StateEdit() {
      this.isStateEdit = false;
    },
    StateQuitEdit() {
      this.$refs.form.clearValidate();
      this.isStateEdit = true;
      this.init();
    },
    editYieldEdit() {
      this.isYieldEdit = false;
    },
    async saveStateEdit() {
      if (this.form.scanInterval < 0) {
        this.$message.warning('采集频率不能为负数');
        return false;
      }
      if (this.form.scanInterval > 600000) {
        this.$message.warning('采集频率不能大于一小时');
        return false;
      }
      this.$refs.form.validate(async valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form));
          if (this.form.independentAlarmTopic) {
            data.alarmCondition = this.alarmCondition;
          }
          const { fail } = await this.$apis.StateConfig.saveState(data);
          if (!fail) {
            this.StateQuitEdit();
            return false;
          }
        }
      });
    },
    quitYieldEdit() {
      this.isYieldEdit = true;
      this.init();
    },
    async saveYieldEdit() {
      let tagList = [];
      this.tagList.forEach(item => {
        this.paramId.forEach(ele => {
          if (ele === item.paramId) {
            tagList.push({ tag: item.tag, paramName: item.paramName, paramId: item.paramId });
          }
        });
      });
      const data = {
        deviceId: this.deviceId,
        tagList
      };
      const { fail } = await this.$apis.StateConfig.addYield(data);
      if (!fail) {
        this.quitYieldEdit();
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.scanInterval {
  position: relative;
}
.scanInterval::after {
  content: 'ms';
  position: absolute;
  margin-left: 6px;
  color: #939093;
}
.hint {
  display: block;
  padding-left: 100px;
  color: #909399;
  margin-bottom: 5px;
  font-size: 14px;
}
.switch {
  margin-bottom: 2px;
}
</style>
