<template>
  <div class="deviceCortrol">
    <!-- title -->
    <baseSection name="基础信息">
      <el-form ref="form" :model="form" label-width="120px">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item label="设备名称:">
              <span class="inputtable">{{ form.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="设备编号:">
              <span class="inputtable">{{ form.code }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="负责人:">
              <span class="inputtable">{{ form.updatedBy }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="设备IP:">
              <span class="inputtable">{{ form.ip }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="设备类型:">
              <span class="inputtable">{{ form.deviceTypeName }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="设备模板:">
              <span class="inputtable">{{ form.deviceTemplateName }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="采集频率:">
              <span class="inputtable">{{ form.frequency }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7" v-if="isOpenSecret">
            <el-form-item label="设备密钥:" prop="secret">
              <span class="inputtable">{{ form.secret }}</span>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7" v-if="isOpenSecret">
            <el-form-item label="上云编号:" prop="iotDeviceId">
              <span class="inputtable">{{ form.iotDeviceId }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </baseSection>
    <!-- main -->
    <baseSection name="扩展信息" v-if="form.deviceExpandListInfo.length">
      <el-form ref="form" label-width="120px">
        <el-row>
          <el-col :offset="0" :span="7" v-for="item in form.deviceExpandListInfo" :key="item.fieldName">
            <el-form-item :label="item.fieldName + ':'">
              <span class="inputtable">{{ item.fieldValue }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </baseSection>
    <!-- main -->
    <baseSection name="设备文档" v-if="false">
      <Basetable :multi="false" :data="form.deviceFileListInfo" :columns="columns" :operations="operations" />
    </baseSection>
  </div>
</template>

<script>
import Basetable from '@/components/table/base-table/base-table';
import { TABLE } from '/app.config';
import baseSection from '@/components/section/base-section';
const { download } = TABLE.icons;
export default {
  name: 'deviceOEE',
  props: {
    formData: Object
  },
  components: {
    Basetable,
    baseSection
  },
  data() {
    return {
      form: {
        name: '',
        secret: null,
        iotDeviceId: '',
        frequency: '',
        realName: '',
        deviceFileListInfo: [],
        deviceExpandListInfo: []
      },
      realName: '',
      columns: [
        {
          prop: 'fileName',
          label: '文件名'
        },
        {
          prop: 'size',
          label: '文件大小'
        }
      ],
      operations: {
        width: 200,
        handles: [
          {
            icon: download,
            name: '下载',
            handle: this.download
          }
        ]
      },
      deviceId: this.$parent.deviceId,
      // 是否展示设备密钥
      isOpenSecret: false
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init() {
      // const { userType } = JSON.parse(localStorage.getItem('user'));
      // if (userType === '00' || userType === '01') {
      //   this.isOpenSecret = true;
      // }

      if (!this.deviceId) {
        return false;
      }

      const res = await this.$apis.device.getDeviceInfo({ deviceId: this.deviceId });
      this.form = { ...res.data, deviceExpandListInfo: [] };
      // this.form.deviceFileListInfo = this.form.deviceFileListInfo.map(item => {
      //   return {
      //     ...item,
      //     ...item.fileInfo,
      //     size: `${parseFloat(item.fileInfo.size / 1024).toFixed(2)} MB`
      //   };
      // });
      // this.form = { ...this.form, ...this.form.sysUserInfo };
    },
    // 下载按钮
    download(item) {
      let url = `${process.env.VUE_APP_AESSETS_URL}${item.url}`;
      window.open(url);
    }
  }
};
</script>
<style lang="scss">
.btn_table {
  // margin-left: 10px;
  margin-bottom: 10px;
}
.btn_table_upload {
  margin-bottom: 10px;
}
.deviceCortrol {
  margin-top: 20px;
}
.el-icon-warning:before {
  font-size: 18px;
}
.hint_text {
  color: #000;
  font-size: 14px;
  margin: 0 10px;
}
.el-input,
.el-select {
  width: 100%;
}
.inputtable {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #121212;
}
</style>
