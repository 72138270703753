<template>
  <div class="DeviceChart" @mouseover="into()" @mouseleave="out()">
    <!-- <div class="DeviceChart"> -->
    <div class="chartHeader">
      <div class="leftBox">
        <div class="img">
          <img :src="baseip + data.iconUrl" alt="" />
        </div>
        <div class="info">
          <span class="name">{{ data.paramName }}</span>
          <span class="type"
            >{{ data.data[data.data.length - 1] }} <span class="unit" :title="data.unit">{{ data.unit }}</span>
          </span>
        </div>
      </div>
      <div class="rightBox">
        <el-row>
          <el-col :offset="9" :span="2">
            <el-button class="moveRights iconfont icon-dcdaochu1 searchBtn" @click="exportParams"> 导出 </el-button>
          </el-col>
          <el-col :span="4">
            <el-date-picker :picker-options="timeChanger" class="moveRights" v-model="dateDay" value-format="yyyy-MM-dd" type="date" :editable="false" placeholder="选择日期"> </el-date-picker>
          </el-col>
          <el-col :span="9">
            <el-time-picker
              is-range
              v-model="dateTime"
              :picker-options="timeChange"
              value-format="HH:mm:ss"
              range-separator="-"
              :editable="false"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              class="moveRight"
            >
            </el-time-picker>
            <el-button class="moveRight export searchBtn searchBtnS" icon="el-icon-search" @click="search"> 查询 </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div :class="`${data.tag + data.paramCode}`" v-if="Xdata.length"></div>
    <el-row v-else>
      <el-col :offset="11" :span="4" class="Nulltext">暂无数据</el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'DeviceChart',
  props: {
    // 循环数据
    chartData: {
      type: Object,
      require: true
    },
    color: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      // chartData大对象
      data: null,
      dateDay: null,
      dateTime: null,
      timeChanger: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      },
      timeChange: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      },
      // 展示数据
      Xdata: [],
      Ydata: [],
      timer: null,
      myChart: null,
      baseip: ''
    };
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  async created() {
    this.baseip = process.env.VUE_APP_ICONIMG_URL;
    this.update();
    this.timer = setInterval(() => {
      // if (this.Xdata.length) {
      this.update();
      // }
    }, 5000);
  },
  watch: {
    Xdata() {
      if (this.Xdata.length) {
        this.init();
      } else {
        return false;
      }
    }
  },
  methods: {
    async update() {
      this.data = this.chartData;
      this.data.time = [];
      this.data.data = [];
      const ParamsDataRes = await this.$apis.device.getParamsData({ tags: this.$parent.$parent.tags, deviceId: this.$parent.$parent.deviceId });
      if (!ParamsDataRes.length) {
        return false;
      }
      ParamsDataRes.forEach(item => {
        this.data.time.push(item[0]);
        this.data.data.push(item[this.data.index]);
      });
      this.data.time.shift();
      this.data.data.shift();
      for (let i = 0; i < this.data.time.length; i++) {
        this.data.time[i] = this.data.time[i].substr(0, 19);
      }
      for (let i = 0; i < this.data.data.length; i++) {
        if (this.data.data[i] === 'true') {
          this.data.data[i] = 1;
        }
        if (this.data.data[i] === 'false') {
          this.data.data[i] = 0;
        }
      }
      this.Xdata = JSON.parse(JSON.stringify(this.data.time));
      this.Ydata = JSON.parse(JSON.stringify(this.data.data));
      if (!this.Xdata.length) {
        return false;
      }
    },
    into() {
      clearInterval(this.timer);
    },
    async exportParams() {
      const res = await this.$apis.device.validExportParam({
        deviceId: this.$parent.$parent.deviceId,
        tag: this.data.tag,
        beginTime: this.dateTime && this.dateDay ? `${this.dateDay} ${this.dateTime[0]}` : '',
        endTime: this.dateTime && this.dateDay ? `${this.dateDay} ${this.dateTime[1]}` : ''
      });
      if (!res) {
        return false;
      }
      this.$apis.device.exportParam({
        deviceId: this.$parent.$parent.deviceId,
        tag: this.data.tag,
        beginTime: this.dateTime && this.dateDay ? `${this.dateDay} ${this.dateTime[0]}` : '',
        endTime: this.dateTime && this.dateDay ? `${this.dateDay} ${this.dateTime[1]}` : ''
      });
    },
    out() {
      clearInterval(this.timer);
      this.timer = null;
      this.timer = setInterval(() => {
        this.update();
      }, 10000);
    },
    getSecondByDateSub(begin, end) {
      let beginDate = new Date(begin);
      let endDate = new Date(end);
      let diff = endDate.getTime() - beginDate.getTime();
      let sec = diff / 1000;
      return sec;
    },
    async search() {
      if (this.dateDay === null || this.dateTime === null) {
        this.$message.warning('缺少搜索关键词“时间范围”');
        return false;
      }
      const diff = this.getSecondByDateSub(`${this.dateDay} ${this.dateTime[0]}`, `${this.dateDay} ${this.dateTime[1]}`);
      if (diff > 3600) {
        this.$message.warning('时间范围超过一小时');
        return false;
      }
      const data = {
        deviceId: this.$parent.$parent.deviceId,
        tag: this.data.tag,
        beginTime: `${this.dateDay} ${this.dateTime[0]}`,
        endTime: `${this.dateDay} ${this.dateTime[1]}`
      };
      const res = await this.$apis.device.searchParamsData(data);
      if (res.length) {
        let NewXdata = [];
        let NewYdata = [];
        res.forEach((item, index) => {
          if (index > 0) {
            NewXdata.push(item[0]);
            NewYdata.push(item[1]);
            for (let i = 0; i < NewYdata.length; i++) {
              if (NewYdata[i] === 'false') {
                NewYdata[i] = 0;
              } else if (NewYdata[i] === 'true') {
                NewYdata[i] = 1;
              }
            }
          }
        });
        this.Xdata = NewXdata;
        this.Ydata = NewYdata;
        clearInterval(this.timer);
        this.timer = null;
      } else {
        this.$message.warning('该段时间未采集数据');
      }
    },
    init() {
      this.$nextTick(() => {
        let chartDom = document.querySelector(`.${this.data.tag + this.data.paramCode}`);
        chartDom.style.height = '200px';
        this.myChart = echarts.init(chartDom);
        let option = {
          grid: {
            left: '0%',
            right: '1%',
            bottom: '0%',
            top: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.Xdata
          },
          tooltip: {
            trigger: 'axis'
          },
          yAxis: {
            type: 'value'
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          series: [
            {
              data: this.Ydata,
              type: 'line',
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(240, 142, 3, 0.14)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(253, 222, 104,0.05)'
                  }
                ])
              },
              itemStyle: {
                color: '#e99a02',
                lineStyle: {
                  color: '#e99a02'
                }
              },
              smooth: true
            }
          ]
        };
        option && this.myChart.setOption(option);
        this.myChart.group = 'group1';
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.Nulltext {
  line-height: 200px;
}
.DeviceChart {
  width: 100%;
  height: 300px;
  .chartHeader {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    .export {
      margin-right: 10px;
    }
    .rightBox {
      position: relative;
      flex: 1;
      // transform: translateX(10px);
      .el-row {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .leftBox {
      display: flex;
      align-items: center;
      width: 300px;
      height: 80px;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-right: 10px;
        width: 50px;
        border: 1px solid #ebebeb;
        height: 50px;
      }
      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .name {
          font-size: 20px;
        }
        .type {
          width: 200px;
          font-size: 12px;
          color: #a3a3a3;
        }
      }
    }
  }
}
.searchBtn {
  padding: 10px 20px;
  position: relative;
  margin-left: 3px;
}
.searchBtnS {
  position: relative;
  top: -2px;
}
.moveRight {
  transform: translateX(40px);
}
.moveRights {
  transform: translateX(30px);
}
.unit {
  display: inline-block;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
