<template>
  <div class="device_main">
    <!-- 头部 -->
    <div class="device_header">
      <div class="device_img">
        <img :src="baseip + deviceinfo.iconUrl" alt="" />
      </div>
      <div class="deviceInfo">
        <div class="deviceName">
          <span class="name">{{ deviceinfo.name }}</span>
          <span class="status" :class="deviceinfo.deviceStatus === 2 ? 'OFFLINE' : deviceinfo.deviceStatus === 1 ? 'ONLINE' : 'INACTIVE'">
            {{ deviceinfo.deviceStatus === 2 ? '离线' : deviceinfo.deviceStatus === 1 ? '在线' : '未激活' }}</span
          >
        </div>
        <!--        <div class="Org">{{ deviceinfo.factoryName }} > {{ deviceinfo.workShopName }} > {{ deviceinfo.lineName }}> {{ deviceinfo.name }}</div>-->
      </div>
      <div class="backbtn" v-if="$route.query.system !== 'csy'"><el-button @click="$router.back()" icon="el-icon-back">返回</el-button></div>
    </div>
    <!-- main -->
    <div class="fence"></div>
    <div class="device_detail">
      <div class="device_nav">
        <div v-for="item in Nav_Array" :key="item.name">
          <div v-if="!item.disabled" :class="item.name === Nav_name ? 'nav_item nav_active' : 'nav_item'" @click="changNav(item.name)">{{ item.label }}</div>
        </div>
      </div>
      <div class="device_detailPage">
        <component :is="Nav_name" @changeupdate="init()" :formData="form" @changgeImg="changgeDeviceImg"></component>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceCortrol from './deviceCortrol/deviceCortrol.vue';
import deviceOEE from './deviceOEE/index.vue';
import Alarm from './Alarm/index.vue';
import deviceDetail from './Detail/index.vue';
import OEEconfig from './DeviceOEEconfig/index.vue';
import AlarmConfig from './AlarmConfig/index.vue';
import LossAnalyze from './LossAnalyze/index.vue';
import runningState from './runningState/index';
import StateConfig from './StateConfig/index';
export default {
  data() {
    return {
      deviceId: '',
      deviceinfo: {},
      Nav_name: 'deviceOEE',
      baseip: '',
      form: {
        iconUrl: ''
      },
      Nav_Array: [
        // {
        //   label: '设备信息',
        //   name: 'deviceDetail',
        //   disabled: false
        // },
        {
          label: 'OEE',
          name: 'deviceOEE',
          disabled: false
        },
        {
          label: '参数监控',
          name: 'DeviceCortrol',
          disabled: false
        },
        {
          label: '报警',
          name: 'Alarm',
          disabled: false
        },
        {
          label: '切片计算',
          name: 'LossAnalyze',
          disabled: false
        },
        {
          label: '状态配置',
          name: 'StateConfig',
          disabled: true
        },
        {
          label: '运行状态',
          name: 'runningState',
          disabled: true
        },
        {
          label: '效率配置',
          name: 'OEEconfig',
          disabled: true
        },
        {
          label: '报警配置',
          name: 'AlarmConfig',
          disabled: false
        }
      ]
    };
  },
  components: {
    DeviceCortrol,
    deviceOEE,
    Alarm,
    deviceDetail,
    OEEconfig,
    AlarmConfig,
    LossAnalyze,
    runningState,
    StateConfig
  },
  created() {
    this.baseip = process.env.VUE_APP_AESSETS_URL;
    this.Nav_name = this.$route.query.system === 'csy' ? 'DeviceCortrol' : 'deviceOEE';
    this.Nav_Array.forEach(item => {
      switch (item.label) {
        case '参数监控':
          item.disabled = this.$route.query.system !== 'csy';
          break;
        case 'OEE':
        case '报警':
        case '切片计算':
        case '报警配置':
        case '效率配置':
          item.disabled = this.$route.query.system === 'csy';
          break;
      }
    });
    if (this.$route.query.data) {
      localStorage.setItem('deviceId', this.$route.query.data);
      this.deviceId = this.$route.query.data;
      this.init();
    } else if (localStorage.getItem('deviceId')) {
      this.deviceId = localStorage.getItem('deviceId');
      this.init();
    } else {
      this.$router.push('/deviceInfo');
      return false;
    }
  },
  methods: {
    async changgeDeviceImg(url) {
      this.form.iconUrl = url;
      this.isOpen = true;
    },
    async init() {
      const deviceId = this.deviceId;
      if (!deviceId) {
        return false;
      }
      const res = await this.$apis.device.getDeviceInfo({ deviceId });
      this.deviceinfo = res.data;
      if (this.$route.query.system !== 'csy') {
        if (this.deviceinfo.isOpenProduce) {
          this.Nav_Array.forEach(item => {
            switch (item.label) {
              case '状态配置':
                item.disabled = false;
                break;
              case '运行状态':
                item.disabled = false;
                break;
            }
          });
        } else {
          this.Nav_Array.forEach(item => {
            switch (item.label) {
              case 'OEE':
                item.disabled = false;
                break;
              case '切片计算':
                item.disabled = false;
                break;
              case '效率配置':
                item.disabled = false;
                break;
            }
          });
        }
      }
    },
    changNav(data) {
      this.Nav_name = data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './index.scss';
.device_img > .el-button {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
}
.device_img > .el-button > span > .el-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.device_img {
  position: relative;
}
.device_img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.backbtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
