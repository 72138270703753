<template>
  <div class="TodayDevice">
    <div class="pieChartShowbox"></div>
    <div class="TextShow" v-if="data">
      <div class="TextOee">设备OEE:{{ (data.oeeRate * 100).toFixed(2) + '%' }}</div>
      <div class="Textavailability">可用率{{ (data.availabilityRate * 100).toFixed(2) + '%' }}</div>
      <div class="Textperformance">表现指数{{ (data.performanceRate * 100).toFixed(2) + '%' }}</div>
      <div class="Textquality">质量指数{{ (data.qualityRate * 100).toFixed(2) + '%' }}</div>
    </div>
    <div class="ProgessShow" v-if="data">
      <div class="availabilityRate">
        <span>可用率</span>
        <el-progress color="#438edb" text-inside :stroke-width="26" :percentage="(data.availabilityRate * 100).toFixed(2) - 0"></el-progress>
      </div>
      <div class="performanceRate">
        <span>表现指数</span>
        <el-progress color="#f39800" text-inside :stroke-width="26" :percentage="(data.performanceRate * 100).toFixed(2) - 0"></el-progress>
      </div>
      <div class="qualityRate">
        <span>质量指数</span>
        <el-progress color="#19a572" text-inside :stroke-width="26" :percentage="(data.qualityRate * 100).toFixed(2) - 0"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      deviceId: '',
      mychart: null,
      data: null
    };
  },
  props: {
    OeeData: {
      type: Object,
      require: true
    }
  },
  mounted() {
    this.data = this.$props.OeeData;
    this.deviceId = this.$parent.$parent.deviceId;
    this.init();
  },
  methods: {
    async init() {
      const OeeRes = await this.$apis.deviceOee.TodayDeviceOee({ deviceId: this.deviceId });
      let chartDom = document.querySelector('.pieChartShowbox');
      this.myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {d}%'
        },
        series: [
          {
            name: '设备效率',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '10',
                fontWeight: 'bold'
              }
            },
            data: [
              {
                value: OeeRes.availabilityRate,
                name: '可用率',
                itemStyle: {
                  color: '#438edb'
                }
              },
              {
                value: OeeRes.performanceRate,
                name: '表现指数',
                itemStyle: {
                  color: '#f39800'
                }
              },
              {
                value: OeeRes.qualityRate,
                name: '质量指数',
                itemStyle: {
                  color: '#19a572'
                }
              }
            ]
          }
        ]
      };

      option && this.myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
.TodayDevice {
  display: flex;
  justify-content: space-between;
  height: 251px;
  .pieChartShowbox {
    position: relative;
    display: flex;
    flex: 1;
    .pieChartShow {
      flex: 1;
      height: 100%;
    }
    .OeeShow {
      position: absolute;
    }
  }
  .TextShow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: left;
    padding: 50px 0px;
  }
  .ProgessShow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 50px 0px;
    .availabilityRate {
      width: 70%;
      position: relative;
      span {
        position: absolute;
        left: -80px;
      }
    }
    .performanceRate {
      width: 70%;
      position: relative;
      span {
        position: absolute;
        left: -80px;
      }
    }
    .qualityRate {
      width: 70%;
      position: relative;
      span {
        position: absolute;
        left: -80px;
      }
    }
  }
}
.el-progress-bar__inner,
.el-progress-bar__outer {
  border-radius: 0px;
}
.Textavailability,
.Textperformance,
.Textquality,
.TextOee {
  width: 180px;
  text-align: left;
  position: relative;
}
.TextOee {
  transform: translate(-20px);
  font-size: 16px;
  color: #121212;
}
.Textavailability::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  top: 5px;
  border-radius: 2px;
  background-color: #438edb;
}
.Textperformance::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  top: 5px;
  background-color: #f39800;
}
.Textquality::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  top: 5px;
  background-color: #19a572;
}
// .el-progress__text {
//   position: absolute;
//   right: -20px;
//   top: 5px;
//   font-size: 14px !important;
// }
</style>
