<template>
  <div class="lossAnalyze">
    <div class="search">
      <div class="searchDate">
        <el-date-picker :picker-options="timeChange" v-model="date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
      </div>
      <div class="searchBtn">
        <el-button class="iconfont icon-dcsousuo" @click="init()">查询</el-button>
      </div>
    </div>
    <!--    <div class="topCharts">-->
    <!--      <div class="left">-->
    <!--        <base-section name="设备原因停机占比">-->
    <!--          <LostType :deviceId="deviceId" :timer="timer" :DetailData="DetailData" :color="Detailcolor" />-->
    <!--        </base-section>-->
    <!--      </div>-->
    <!--      <div class="right">-->
    <!--        <base-section name="停机损失分析占比">-->
    <!--          <LostType :deviceId="deviceId" :timer="timer" :DetailData="TotalData" :color="Totalcolor" />-->
    <!--        </base-section>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <base-section>-->
    <!-- 此处传过去的设备ID全小写 -> deviceid -->
    <Device-run-status @open="isTable = true" :deviceid="deviceId" :date="statusDate" style="margin-bottom: 20px" />
    <!--    </base-section>-->
    <!--    <base-section name="停机列表">-->
    <!--      &lt;!&ndash; 此处传过去的设备ID全小写 -> deviceid &ndash;&gt;-->
    <!--      <Lost-table v-if="isTable" :deviceid="deviceId" :timer="timer" @refresh="init()" />-->
    <!--    </base-section>-->
  </div>
</template>

<script>
// import LostTable from './lostTable.vue';
// import baseSection from '@/components/section/base-section';
import DeviceRunStatus from './DeviceRunStatus.vue';
// import LostType from './lostTypeCharts.vue';
export default {
  data() {
    return {
      deviceId: '',
      // YYYY-MM-DD
      date: '',
      // 时间戳
      timer: '',
      // 未来时间不可选
      timeChange: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      },
      Detailcolor: ['#ea3837', '#f39800', '#19a572', '#438edb', '#5a5a5a', '#dcdcdc'],
      Totalcolor: ['#f39800', '#f3f3f3'],
      TotalData: [],
      DetailData: [],
      isTable: false
    };
  },
  components: { DeviceRunStatus },
  created() {
    this.deviceId = this.$parent.deviceId;
    this.init();
  },
  methods: {
    async init() {
      this.TotalData = [];
      let date = (this.date ? this.date : new Date().toLocaleDateString()).concat(' 00:00:00');
      this.statusDate = this.date;
      this.timer = new Date(date).getTime();
      const { haltWithReason, haltWithoutReason } = await this.$apis.lostAnalyze.getCharts({ deviceId: this.deviceId, recordDate: this.timer });
      // 筛选有无原因图数据
      let haveCause = 0;
      haltWithReason.forEach(item => {
        haveCause += item.value;
      });
      this.TotalData.push({
        value: haveCause,
        name: '停机有原因',
        percentage: haveCause / (haltWithoutReason.value + haveCause)
      });
      this.TotalData.push({
        value: haltWithoutReason.value,
        name: haltWithoutReason.name,
        percentage: haltWithoutReason.value / (haltWithoutReason.value + haveCause)
      });

      // 筛选原因具体数据
      let remain = 0;
      haltWithReason.forEach((item, index) => {
        if (index === haltWithReason.length - 1) {
          item.percentage = 1 - remain;
        } else {
          item.percentage = (item.value / haveCause).toFixed(4);
          remain = remain + (item.percentage - 0);
        }
      });
      this.DetailData = haltWithReason;
    }
  }
};
</script>
<style lang="scss" scoped>
.topCharts {
  width: 100%;
  height: 400px;
  margin-top: 30px;
  display: flex;
  .left {
    flex: 1;
    margin-right: 30px;
  }
  .right {
    flex: 1;
  }
}
.search {
  display: flex;
  justify-content: right;
  // margin-bottom: 10px;
}
.searchDate {
  margin: 0px 10px;
}
.searchBtn {
  padding: 0;
}
</style>
