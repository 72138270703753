<template>
  <div class="DeviceRunStatus">
    <div class="DeviceStatusCard" v-if="data">
      <div class="runningTimer">
        <div class="box">
          <div class="icon">
            <img src="./img/sbxl_icon_yxsc.svg" alt="" />
          </div>
          <div class="timer">
            <div class="running">运行时长</div>
            <div class="timeS">
              <span class="timerNum">{{ Math.floor(data.totalRunningTime / 60000 / 60) }} </span>
              {{ '小时' }}
              <span class="timerNum">{{ Math.floor((data.totalRunningTime / 60000) % 60) }}</span>
              {{ '分钟' }}
            </div>
          </div>
        </div>
      </div>
      <div class="freeTimer">
        <div class="box">
          <div class="icon">
            <img src="./img/sbxl_icon_kxsc.svg" alt="" />
          </div>
          <div class="timer">
            <div class="free">空闲时长</div>
            <div class="timeS">
              <span class="timerNum">{{ Math.floor(data.totalFreeTime / 60000 / 60) }} </span>
              {{ '小时' }}
              <span class="timerNum">{{ Math.floor((data.totalFreeTime / 60000) % 60) }}</span>
              {{ '分钟' }}
            </div>
          </div>
        </div>
      </div>
      <div class="warningTimer">
        <div class="box">
          <div class="icon">
            <img src="./img/sbxl_icon_bjsc.svg" alt="" />
          </div>
          <div class="timer">
            <div class="warning">故障时长</div>
            <div class="timeS">
              <span class="timerNum">{{ Math.floor(data.totalAlarmTime / 60000 / 60) }} </span>
              {{ '小时' }}
              <span class="timerNum">{{ Math.floor((data.totalAlarmTime / 60000) % 60) }}</span>
              {{ '分钟' }}
            </div>
          </div>
        </div>
      </div>
      <div class="offTimer">
        <div class="box">
          <div class="icon">
            <img src="./img/sbxl_icon_lxsc.svg" alt="" />
          </div>
          <div class="timer">
            <div class="off">离线时长</div>
            <div class="timeS">
              <span class="timerNum">{{ Math.floor(data.totalOfflineTime / 60000 / 60) }} </span>
              {{ '小时' }}
              <span class="timerNum">{{ Math.floor((data.totalOfflineTime / 60000) % 60) }}</span>
              {{ '分钟' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-col :offset="10" :span="4" style="margin-top: 20px; color: #909399" v-else>今日此设备暂无数据</el-col>
    <div ref="statusCharts" class="DeviceStatusChart1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      deviceId: '',
      data: null,
      totalSS: 86400000
    };
  },
  props: ['deviceid', 'date'],
  watch: {
    date() {
      this.init();
    }
  },
  mounted() {
    this.deviceId = this.deviceid;
    this.init();
  },
  methods: {
    async init() {
      let res;
      if (this.date) {
        res = await this.$apis.deviceOee.deviceRunStatus({ deviceId: this.deviceId, beginTime: `${this.date} 00:00:00`, endTime: `${this.date} 23:59:59` });
      } else {
        res = await this.$apis.deviceOee.deviceRunStatus({ deviceId: this.deviceId });
      }
      // let chartDom = document.querySelector('.DeviceStatusChart1');
      let chartDom = this.$refs.statusCharts;
      let myChart = echarts.init(chartDom);
      let option;
      if (res) {
        this.data = res;
        this.data.end = new Date(this.data.endTime);
        this.data.end = this.data.end.toLocaleString('chinese', { hour12: false });
        this.data.start = new Date(this.data.startTime);
        this.data.start = this.data.start.toLocaleString('chinese', { hour12: false });
        this.data.data.forEach(item => {
          item.percentum = (item.period / this.totalSS) * 100;
          item.end = new Date(item.endTime);
          item.end = item.end.toLocaleString('chinese', { hour12: false });
          item.start = new Date(item.startTime);
          item.start = item.start.toLocaleString('chinese', { hour12: false });
        });
      } else {
        myChart.clear();
        this.data = res;
        return false;
      }

      let data = [];
      let categories = ['设备状态'];
      let types = [
        { name: '运行时长', color: '#19a572', state: 'RUNNING_STATE' },
        { name: '空闲时长', color: '#f39800', state: 'FREE_STATE' },
        { name: '故障时长', color: '#ea3837', state: 'ALARM_STATE' },
        { name: '离线时长', color: '#dcdcdc', state: 'OFFLINE_STATE' }
      ];
      let startTime = 0;
      for (let i = 0; i < this.data.data.length; i++) {
        let typeItem = {};
        switch (this.data.data[i].state) {
          case 'RUNNING_STATE':
            typeItem = types[0];
            break;
          case 'FREE_STATE':
            typeItem = types[1];
            break;
          case 'ALARM_STATE':
            typeItem = types[2];
            break;
          case 'OFFLINE_STATE':
            typeItem = types[3];
            break;
        }
        let duration = parseFloat((24 * parseFloat(this.data.data[i].percentum)) / 100);
        data.push({
          name: typeItem.name,
          value: [0, startTime, (startTime += duration), duration],
          itemStyle: {
            color: typeItem.color
          }
        });
      }
      function renderItem(params, api) {
        let categoryIndex = api.value(0);
        let start = api.coord([api.value(1), categoryIndex]);
        let end = api.coord([api.value(2), categoryIndex]);
        let height = 30;
        let rectShape = echarts.graphic.clipRectByRect(
          {
            x: start[0],
            y: start[1] - height / 2,
            width: end[0] - start[0],
            height
          },
          {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height
          }
        );
        return (
          rectShape && {
            type: 'rect',
            transition: ['shape'],
            shape: rectShape,
            style: api.style()
          }
        );
      }
      function timerTransiton(val, num = false) {
        let timer = val * 60;
        let hh = Math.floor(timer / 60);
        let mm = Math.floor(timer % 60);
        hh = hh < 10 ? '0'.concat(hh) : hh;
        mm = mm < 10 ? '0'.concat(mm) : mm;
        if (num) {
          return `${hh}:${mm}`;
        } else {
          return `${hh}小时${mm}分钟`;
        }
      }
      option = {
        tooltip: {
          formatter(params) {
            return `${params.marker + params.name}: ${timerTransiton(params.value[3])}<br/>${params.marker}开始时间:${timerTransiton(params.value[1], true)}<br/>${params.marker}结束时间:${timerTransiton(
              params.value[2],
              true
            )}`;
          }
        },
        dataZoom: [
          {
            type: 'inside',
            filterMode: 'weakFilter'
          }
        ],
        grid: {
          top: '5%',
          left: '5%',
          right: '1%',
          bottom: '20%'
        },
        xAxis: {
          min: 0,
          max: 24,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          scale: true,
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter(val) {
              return `${val}h`;
            },
            interval: 0,
            margin: -5
          }
        },
        yAxis: {
          data: categories,
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          nameTextStyle: {
            fontSize: 20
          }
        },
        series: [
          {
            type: 'custom',
            renderItem,
            itemStyle: {
              opacity: 1
            },
            encode: {
              x: [1, 2],
              y: 0
            },
            data
          }
        ]
      };
      option && myChart.setOption(option);
      this.$emit('open');
    }
  }
};
</script>

<style scoped lang="scss">
.month {
  margin-right: 10px;
}
.DeviceStatusCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 120px;
  padding: 10px 0px;
  .runningTimer,
  .offTimer,
  .freeTimer,
  .warningTimer {
    display: flex;
    flex: 1;
    .box {
      padding: 20px;
      display: flex;
      flex: 1;
      margin: 0 10px;
      align-items: center;
    }
  }
}
.timer {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}
.timeS {
  margin-top: 16px;
  transform: translateX(-20px);
  .timerNum {
    color: #333333;
    font-size: 22px;
  }
}
.running,
.free,
.warning,
.off {
  position: relative;
}
.off::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  top: 5px;
  border-radius: 2px;
  background-color: #cccccc;
}
.warning::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  top: 5px;
  border-radius: 2px;
  background-color: #ea3837;
}
.free::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  top: 5px;
  border-radius: 2px;
  background-color: #f39800;
}
.running::before {
  content: '';
  position: absolute;
  left: -20px;
  width: 10px;
  height: 10px;
  top: 5px;
  border-radius: 2px;
  background-color: #19a572;
}
.DeviceStatusChart {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  padding: 5px;
  border-bottom: 1px solid #a3a3a3;
  margin-bottom: 30px;
}
.DeviceStatusChart1 {
  width: 100%;
  height: 100px;
}
.DeviceStatusChart::before {
  content: '0';
  position: absolute;
  color: #a3a3a3;
  bottom: -25px;
}
.DeviceStatusChart::after {
  content: '24';
  position: absolute;
  color: #a3a3a3;
  right: 0;
  bottom: -25px;
}
.DeviceStatusChartBox {
  position: relative;
  height: 100%;
  border-radius: 5px;
}
.subscript {
  position: absolute;
  bottom: -25px;
  right: -25px;
  font-size: 10px;
  color: #a3a3a3;
}
.freebox {
  margin: 0px 2px;
  background-color: #f39800;
}
.offbox {
  background-color: #e7e7e7;
}
.runningbox {
  background-color: #67c23b;
}
.alarmbox {
  background-color: #ff7473;
}
.search {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}
</style>
