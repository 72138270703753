<template>
  <el-dialog title="编辑计算公式" :close-on-click-modal="false" :visible.sync="dialogVisible" width="45%" :before-close="handleClose">
    <div class="editBlock">
      <div class="visualBlock">
        <div><span class="visualText">计算公式</span>={{ equationStr }}</div>
        <div class="visualBlockBtn">
          <el-button @click="clear" type="text">←</el-button>
          <el-button @click="clearAll" type="text">清空</el-button>
        </div>
      </div>
      <div class="arithBlock">
        <div class="paramsArr">
          <div class="ObjectText">参数对象:</div>
          <div class="paramsBtn" :style="paramsList.length > 5 ? 'padding-left:10px' : 'padding-left:0px'">
            <el-button v-for="item in paramsList" :key="item.id" @click="Pushparams(item)">{{ item.name }}</el-button>
          </div>
        </div>
        <div class="calcSymbol">
          <div class="ObjectText">运算符号:</div>
          <div class="calcSymbolBtn">
            <el-button v-for="item in calcSymbolBtn" :key="item" @click="Pushcalc(item)">{{ item }}</el-button>
          </div>
        </div>
        <div class="number">
          <div class="ObjectText">数字键盘:</div>
          <div class="numberBtn">
            <el-button v-for="item in numberBtn" :key="item" @click="Pushnumber(item)">{{ item }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" class="iconfont icon-dcicon_save1" @click="save()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      require: true
    },
    paramsList: {
      type: Array,
      require: true
    },
    arr: {
      type: Array
    },
    str: {
      type: String
    }
  },
  watch: {
    arr() {
      this.equationArr = this.arr || [];
    },
    str() {
      this.equationStr = this.str || [];
    },
    equationArr() {
      this.equationStr = '';
      this.equationArr.forEach(item => {
        this.equationStr = this.equationStr + item.name;
      });
      // this.equationStr;
    }
  },
  mounted() {
    this.equationArr = this.arr || [];
    this.equationStr = this.str || [];
  },
  data() {
    return {
      equationArr: [],
      equationStr: '',
      calcSymbolBtn: ['+', '-', '*', '/', '(', ')'],
      numberBtn: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.']
    };
  },
  methods: {
    handleClose() {
      this.equationStr = '';
      this.equationArr = [];
      this.$emit('close');
    },
    Pushparams(data) {
      this.equationArr.push({ Type: 1, name: data.name, id: data.id });
      this.equationStr = this.equationStr + data.name;
    },
    Pushcalc(data) {
      this.equationArr.push({ Type: 0, name: data });
      this.equationStr = this.equationStr + data;
    },
    Pushnumber(data) {
      this.equationArr.push({ Type: 0, name: data });
      this.equationStr = this.equationStr + data;
    },
    save() {
      this.$emit('getarith', this.equationArr, this.equationStr);
      this.handleClose();
    },
    clear() {
      this.equationArr.pop();
    },
    clearAll() {
      this.equationStr = '';
      this.equationArr = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.editBlock {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 0 20px;
  .visualBlock {
    position: relative;
    height: 150px;
    width: 100%;
    border: 1px solid #e9eceb;
    padding: 10px;
    font-size: 16px;
    .visualText {
      font-weight: 600;
    }
    .visualBlockBtn {
      position: absolute;
      right: 20px;
      bottom: 0px;
    }
  }
  .arithBlock {
    flex: 1;
    padding: 20px;
    .paramsArr,
    .calcSymbol,
    .number {
      margin: 10px 0;
      display: flex;
      .ObjectText {
        width: 100px;
        font-size: 20px;
        white-space: nowrap;
      }
    }
    .numberBtn {
      width: 230px;
      flex-wrap: wrap;
    }
  }
}
.paramsBtn {
  box-sizing: border-box;
  padding-left: 17px;
}
.el-button,
.el-button--default {
  margin: 2px 5px;
}
</style>
