<template>
  <div class="lineCharts"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      lineData: null,
      timer: [],
      free: [],
      run: [],
      warning: [],
      off: []
    };
  },
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  watch: {
    data() {
      this.init();
    },
    deep: true
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.lineData = this.data;
      this.timer = [];
      this.warning = [];
      this.free = [];
      this.run = [];
      this.off = [];
      // eslint-disable-next-line no-unused-vars
      this.lineData.indexList.forEach(item => {
        this.timer.push(item);
      });
      this.lineData.totalAlarmTimeList.forEach(item => {
        item = item / 3600000;
        this.warning.push(item);
      });
      this.lineData.totalFreeTimeList.forEach(item => {
        item = item / 3600000;
        this.free.push(item);
      });
      this.lineData.totalRunningTimeList.forEach(item => {
        item = item / 3600000;
        this.run.push(item);
      });
      this.lineData.totalOfflineTimeList.forEach(item => {
        item = item / 3600000;
        this.off.push(item);
      });
      let chartDom = document.querySelector('.lineCharts');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            let value = '';
            let unit = '小时';
            params.forEach(item => {
              value = item.value;
              if (item.value < 0.5) {
                unit = '分';
                value = item.value * 60;
                if (value < 1) {
                  unit = '秒';
                  value = value * 60;
                }
              }
              str = str.concat(`${item.marker + item.seriesName}:${value.toFixed(2)}${unit}<br/>`);
              unit = '小时';
            });
            return str;
          }
          // }
        },
        legend: {
          top: 'bottom',
          left: '90',
          data: ['运行时长', '空闲时长', '故障时长', '离线时长'],
          itemWidth: 17,
          itemHeight: 6
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '16%',
          top: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timer
        },
        yAxis: {
          type: 'value',
          max: 24
        },
        series: [
          {
            name: '运行时长',
            type: 'line',
            data: this.run,
            itemStyle: {
              color: '#19a572'
            }
          },
          {
            name: '空闲时长',
            type: 'line',
            data: this.free,
            itemStyle: {
              color: '#f39800'
            }
          },
          {
            name: '故障时长',
            type: 'line',
            data: this.warning,
            itemStyle: {
              color: '#EA3837'
            }
          },
          {
            name: '离线时长',
            type: 'line',
            data: this.off,
            itemStyle: {
              color: '#bdbdbd'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>
<style scoped>
.lineCharts {
  width: 100%;
  height: 100%;
}
</style>
