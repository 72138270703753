<template>
  <div class="Yield">
    <div ref="historgam" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    paramsData: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: String
    }
  },
  mounted() {
    if (this.paramsData !== null) {
      this.init();
    }
  },
  watch: {
    paramsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let myChart = echarts.init(this.$refs.historgam);
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '12%',
          right: '2%',
          left: '4%',
          bottom: '10%'
        },
        legend: {
          data: [this.paramsData.tagListName[this.index]],
          top: '3%',
          right: '2%',
          itemWidth: 13,
          itemHeight: 13
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: this.paramsData.indexList,
            axisLabel: {
              interval: 0
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: this.paramsData.tagListName[this.index],
            type: 'bar',
            barGap: '10%',
            emphasis: {
              focus: 'series'
            },
            barWidth: '20px',
            data: this.paramsData[this.item],
            itemStyle: {
              color: '#f39800',
              borderColor: '#e5ad40'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.Yield {
  height: 350px;
  position: relative;
}
.chart {
  height: 100%;
}
</style>
