<template>
  <div class="DeviceRunLog">
    <el-row>
      <el-col :offset="13" :span="11">
        <el-radio-group v-model="radio1" @change="changgeradio">
          <el-radio-button label="7日" @click="getweek"></el-radio-button>
          <el-radio-button label="30日" @click="getmonth"></el-radio-button>
        </el-radio-group>
        <!-- <el-button type="text" :class="isWeek ? '' : 'textNOactive'" @click="getweek">7日</el-button>
        <el-button type="text" :class="isWeek ? 'textNOactive' : ''" class="month" @click="getmonth">30日</el-button> -->
        <Timerpick :vModel="date" startPlaceholder="开始时间" endPlaceholder="结束时间" rangeSeparator="-至-" :clearable="false" :editable="false" type="date" format="yyyy-MM-dd" />
        <el-button class="searchBtn" @click="search">查询</el-button>
      </el-col>
    </el-row>
    <div class="DeviceRunLogBox" v-if="data">
      <div class="top">
        <div class="topRight" v-if="data">
          <div class="pie">
            <Piechart :data="data" />
          </div>
        </div>
        <div class="topLeft">
          <div class="runtimer">
            <div class="icon">
              <img src="./img/sbxl_icon_yxsc.svg" alt="" />
            </div>
            <div class="data">
              <div class="boxTop">运行时长</div>
              <div class="Datadetail">
                <span class="timerClass"> {{ Math.floor(data.totalRunningTime / 60000 / 60).toFixed(0) }}</span>
                {{ '小时' }}
                <span class="timerClass"> {{ Math.floor((data.totalRunningTime / 60000) % 60).toFixed(0) }}</span>
                {{ '分钟' }}
              </div>
            </div>
          </div>
          <div class="freetimer">
            <div class="icon">
              <img src="./img/sbxl_icon_kxsc.svg" alt="" />
            </div>
            <div class="data">
              <div class="boxTop">空闲时长</div>
              <div class="Datadetail">
                <span class="timerClass"> {{ Math.floor(data.totalFreeTime / 60000 / 60).toFixed(0) }}</span>
                {{ '小时' }}
                <span class="timerClass"> {{ Math.floor((data.totalFreeTime / 60000) % 60).toFixed(0) }}</span>
                {{ '分钟' }}
              </div>
            </div>
          </div>
          <div class="warningtimer">
            <div class="icon">
              <img src="./img/sbxl_icon_bjsc.svg" alt="" />
            </div>
            <div class="data">
              <div class="boxTop">故障时长</div>
              <div class="Datadetail">
                <span class="timerClass">{{ Math.floor(data.totalAlarmTime / 60000 / 60).toFixed(0) }}</span>
                {{ '小时' }}
                <span class="timerClass">{{ Math.floor((data.totalAlarmTime / 60000) % 60).toFixed(0) }}</span>
                {{ '分钟' }}
              </div>
            </div>
          </div>
          <div class="offtimer">
            <div class="icon">
              <img src="./img/sbxl_icon_lxsc.svg" alt="" />
            </div>
            <div class="data">
              <div class="boxTop">离线时长</div>
              <div class="Datadetail">
                <span class="timerClass">{{ Math.floor(data.totalOfflineTime / 60000 / 60).toFixed(0) }}</span>
                {{ '小时' }}
                <span class="timerClass">{{ Math.floor((data.totalOfflineTime / 60000) % 60).toFixed(0) }}</span>
                {{ '分钟' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="DeviceRunLogChart">
        <Linecharts :data="data" v-if="data" />
      </div>
    </div>
  </div>
</template>

<script>
import Timerpick from '@/components/TimerPick/index.vue';
import Linecharts from './lineCharts.vue';
import Piechart from './piechart.vue';
export default {
  data() {
    return {
      date: [],
      data: null,
      deviceId: '',
      isSearch: false,
      isWeek: true,
      radio1: '7日'
    };
  },
  components: {
    Linecharts,
    Piechart,
    Timerpick
  },
  mounted() {
    this.deviceId = this.$parent.$parent.deviceId;
    this.init();
  },
  methods: {
    changgeradio(label) {
      switch (label) {
        case '7日':
          this.getweek();
          break;
        case '30日':
          this.getmonth();
          break;
      }
    },
    getweek() {
      this.isWeek = true;
      let days = [];
      let DateD = new Date();
      for (let i = 0; i <= 144; i += 24) {
        let dateItem = new Date(DateD.getTime() - i * 60 * 60 * 1000);
        let y = dateItem.getFullYear();
        let m = dateItem.getMonth() + 1;
        let d = dateItem.getDate();
        let valueItem = `${y}-${m}-${d}`;
        days.push(valueItem);
      }
      this.date[0] = days[days.length - 1];
      this.date[1] = days[0];
      this.isSearch = true;
      this.init();
    },
    getmonth() {
      this.isWeek = false;
      let ago = new Date();
      let now = new Date(ago);
      now.setDate(ago.getDate() - 29);
      let agoDay = `${now.getFullYear()}-${now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1}-${now.getDate()}`;
      let nowDay = `${ago.getFullYear()}-${ago.getMonth() + 1 < 10 ? `0${ago.getMonth() + 1}` : ago.getMonth() + 1}-${ago.getDate()}`;
      this.date[0] = agoDay;
      this.date[1] = nowDay;
      this.isSearch = true;
      this.init();
    },
    async init() {
      let data;
      if (this.isSearch) {
        const datadate = {
          beginTime: this.date.length ? `${this.date[0]} 00:00:00` : '',
          endTime: this.date.length ? `${this.date[1]} 00:00:00` : ''
        };
        if (this.date.length) {
          data = await this.$apis.deviceOee.deviceRunLog({ deviceId: this.deviceId, ...datadate });
        } else {
          data = await this.$apis.deviceOee.deviceRunLog({ deviceId: this.deviceId });
        }
      } else {
        data = await this.$apis.deviceOee.deviceRunLog({ deviceId: this.deviceId });
      }
      this.data = data;
    },
    search() {
      this.isSearch = true;
      this.init();
    }
  }
};
</script>
<style scoped lang="scss">
.month {
  margin-right: 10px;
}
.DeviceRunLogBox {
  display: flex;
  flex-direction: column;
  height: 600px;
  .top {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    .topLeft {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 1;
      .runtimer,
      .freetimer,
      .warningtimer,
      .offtimer {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 45%;
        width: 49%;
        .boxTop {
          position: relative;
          height: 40px;
          line-height: 40px;
          padding-left: 40px;
        }
        .icon {
          width: 80px;
          height: 80px;
        }
        .data {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .Datadetail {
            padding-left: 15px;
            color: #909299;
          }
        }
      }
    }
    .topRight {
      flex: 1;
      padding: 0 20px 20px 20px;
      .pie {
        width: 100%;
        height: 100%;
      }
    }
  }
  .DeviceRunLogChart {
    height: 256px;
  }
}
.timerClass {
  font-size: 24px;
  color: #121212;
}
.runtimer .boxTop::before {
  content: '';
  position: absolute;
  left: 15px;
  width: 10px;
  height: 10px;
  top: 15px;
  background-color: #19a572;
}
.freetimer .boxTop::before {
  content: '';
  position: absolute;
  left: 15px;
  width: 10px;
  height: 10px;
  top: 15px;
  background-color: #f5a623;
}
.warningtimer .boxTop::before {
  content: '';
  position: absolute;
  left: 15px;
  width: 10px;
  height: 10px;
  top: 15px;
  background-color: #ea3837;
}
.offtimer .boxTop::before {
  content: '';
  position: absolute;
  left: 15px;
  width: 10px;
  height: 10px;
  top: 15px;
  background-color: #cdcdcd;
}
.DeviceRunLog {
  margin-bottom: 20px;
}
.searchBtn {
  padding: 10px 20px;
  position: relative;
  margin-left: 3px;
}
.textNOactive {
  color: #afaaaa;
}
/deep/ .el-radio-group,
.el-radio-button,
.el-radio-button__inner {
  height: 36px;
}
/deep/ .el-radio-button__inner {
  padding: 10px 20px;
}
.el-radio-group {
  margin-right: 10px;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  padding: 10px 20px;
  border-radius: 4px 4px 4px 4px;
  background: #fff;
  color: #f39800;
  // border: 1px solid #f39800;
}
</style>
