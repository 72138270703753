<template>
  <div class="deviceCortrol">
    <el-alert type="warning" :closable="false">
      <i class="el-icon-warning"></i>
      <el-button type="text" @click="openEenter">点击此处</el-button>
      <span class="hint_text">可设置生产效率参数</span>
    </el-alert>
    <baseSection name="录入参数" v-if="isEnterParam">
      <EnterParam v-if="isEnterParam" @close="close" />
    </baseSection>
    <baseSection name="今日设备效率" v-if="OeeData">
      <TodayDevice :OeeData="OeeData" v-if="OeeData" />
    </baseSection>
    <!-- <baseSection name="设备运行状态(按天)">
      <DeviceRunStatus :deviceid="deviceId" />
    </baseSection> -->
    <baseSection name="设备运行记录(按时段)">
      <DeviceRunLog :DeviceLog="DeviceLog" />
    </baseSection>
    <baseSection name="历史效率查询">
      <HistoryDeviceStatus :deviceId="deviceId" />
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import EnterParam from './model/EnterParams.vue';
import TodayDevice from './model/TodayDevice.vue';
// import DeviceRunStatus from '../LossAnalyze/DeviceRunStatus.vue';
import DeviceRunLog from './model/DeviceRunLog.vue';
import HistoryDeviceStatus from './model/HistoryDeviceStatus.vue';
export default {
  name: 'deviceOEE',
  data() {
    return {
      isEnterParam: false,
      deviceId: '',
      OeeData: null,
      DeviceLog: null
    };
  },
  components: {
    EnterParam,
    TodayDevice,
    // DeviceRunStatus,
    DeviceRunLog,
    HistoryDeviceStatus,
    baseSection
  },
  async created() {
    this.deviceId = this.$parent.deviceId;
    const OeeRes = await this.$apis.deviceOee.TodayDeviceOee({ deviceId: this.deviceId });
    this.OeeData = OeeRes;
    const data = await this.$apis.deviceOee.deviceRunLog({ deviceId: this.deviceId });
    this.DeviceLog = data;
  },
  methods: {
    openEenter() {
      this.isEnterParam = true;
    },
    close() {
      this.isEnterParam = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.deviceCortrol {
  margin-top: 0px !important;
}
.el-icon-warning:before {
  font-size: 18px;
}
.hint_text {
  position: absolute;
  top: 12px;
  color: #000;
  font-size: 14px;
  margin: 0 10px;
}
.el-alert--warning.is-light {
  background-color: #fff;
  color: #e6a23c;
  width: 700px;
  height: 40px;
}
.el-alert__description {
  position: relative;
}
.el-icon-warning {
  position: absolute;
  top: 13px;
  left: 0;
}
</style>
