<template>
  <div class="runningState">
    <baseSection name="当前设备状态">
      <div class="device_grid" :style="`background`" v-if="deviceState">
        <div class="img" :class="deviceState.state"></div>
        <div class="detail">
          <div class="deviceStateName">
            {{ deviceState.state === 'running' ? '运行' : deviceState.state === 'free' ? '空闲' : deviceState.state === 'alarm' ? '报警' : '离线' }}
          </div>
          <div class="time">
            {{ deviceState.timerFormatter }}
          </div>
        </div>
      </div>
    </baseSection>
    <baseSection name="设备运行状态">
      <div class="search">
        <div class="searchDate">
          <el-date-picker :picker-options="timeChange" v-model="statusDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
        </div>
        <el-button class="iconfont icon-dcsousuo" @click="init()">查询</el-button>
      </div>
      <Device-run-status @open="isTable = true" :deviceid="deviceId" :date="statusDate" style="margin-bottom: 20px" />
    </baseSection>
    <baseSection name="当前设备产量">
      <div v-if="paramsTimerList.length" class="grid">
        <div v-for="(item, index) in paramsTimerList" :key="index" class="paramsGrid">
          <div class="paramsName">
            <div :title="item.paramName">{{ item.paramName }}</div>
            <div class="time">
              {{ item.time }}
            </div>
          </div>
          <div class="paramsValue" :title="item.produceNum">
            {{ item.produceNum }}
          </div>
        </div>
      </div>
      <div v-else class="grid">
        <div class="paramsGrid">暂无生产数据</div>
      </div>
    </baseSection>
    <baseSection name="设备产量统计">
      <div class="search">
        <div class="searchDate">
          <Timerpick :todayNow="false" :vModel="date" startPlaceholder="开始时间" endPlaceholder="结束时间" rangeSeparator="-至-" :clearable="false" :editable="false" type="date" format="yyyy-MM-dd" />
        </div>
        <el-button class="iconfont icon-dcsousuo" @click="search">查询</el-button>
      </div>
      <div v-if="paramsData">
        <Yield :paramsData="paramsData" v-for="(item, index) in paramsData.tagList" :key="item" :index="index" :item="item" />
      </div>
    </baseSection>
  </div>
</template>

<script>
import { timerFormatter } from '@/axios/utils.js';
import baseSection from '@/components/section/base-section';
import DeviceRunStatus from './model/DeviceRunStatus.vue';
import Yield from './model/Yield/index.vue';
import Timerpick from '@/components/TimerPick/index.vue';
export default {
  components: { baseSection, DeviceRunStatus, Yield, Timerpick },
  data() {
    return {
      deviceId: this.$parent.deviceId,
      statusDate: '',
      date: [],
      paramsTimerList: [],
      paramsData: null,
      timeChange: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      },
      deviceState: null
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const res = await this.$apis.runningState.getProduceRealTime({ deviceId: this.deviceId });
      const stateRes = await this.$apis.runningState.pulseState({ deviceId: this.deviceId });
      this.deviceState = stateRes;
      this.deviceState.timerFormatter = timerFormatter('yyyy-MM-dd hh:mm:ss', this.deviceState.timestamp);
      this.paramsTimerList = res;
      this.paramsTimerList.forEach(item => {
        if (item.time) {
          item.time = timerFormatter('yyyy-MM-dd hh:mm:ss', item.time);
        }
      });
      this.search();
    },
    /**
     * 时间差返回函数
     * @begin 开始时间
     * @end 结束时间
     * return 时间差(时间戳)
     */
    getSecondByDateSub(begin, end) {
      let beginDate = new Date(begin);
      let endDate = new Date(end);
      let diff = endDate.getTime() - beginDate.getTime();
      let sec = diff / 1000;
      return sec;
    },
    async search() {
      let data;
      /*****
       * 仅选择一个时间情况
       */
      if (this.date.length === 1) {
        this.$message.warning('查询时间未选择完整');
        return false;
      }
      if (!this.date[0] && this.date.length === 2) {
        this.$message.warning('查询时间未选择完整');
        return false;
      }
      /*****
       * 用户时间控件使用正确,对时间范围判断
       */
      if (this.date.length === 2) {
        const datadate = {
          beginTime: this.date.length ? `${this.date[0]} 00:00:00` : '',
          endTime: this.date.length ? `${this.date[1]} 00:00:00` : ''
        };
        const diff = this.getSecondByDateSub(`${datadate.beginTime}`, `${datadate.endTime}`);
        if (diff > 3600 * 24 * 14) {
          this.$message.warning('时间范围超过15天');
          return false;
        }
        data = await this.$apis.runningState.getDeviceProduce({ deviceId: this.deviceId, ...datadate });
      } else {
        data = await this.$apis.runningState.getDeviceProduce({ deviceId: this.deviceId });
      }
      this.paramsData = data;
    }
  }
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  justify-content: right;
}
.searchDate {
  margin: 0px 10px;
}
.searchBtn {
  padding: 10px 20px;
  position: relative;
  margin-left: 3px;
}
.grid {
  display: flex;
  overflow-x: auto;
}
.paramsGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 288px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  color: #909399;
  margin: 0px 24px 10px 0px;
}
.paramsName {
  width: 60%;
  color: #333333;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time {
  color: #909399;
  font-size: 12px;
}
.paramsValue {
  width: 30%;
  color: #121212;
  font-size: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.device_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 278px;
  height: 80px;
  margin-bottom: 30px;
  .img {
    width: 80px;
    height: 80px;
  }
  .offline {
    background: url(./img/offline.png);
  }
  .free {
    background: url(./img/free.png);
  }
  .running {
    background: url(./img/running.png);
  }
  .alarm {
    background: url(./img/alarm.png);
  }
  .detail {
    margin-left: 20px;
    .deviceStateName {
      color: #121212;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .time {
      color: #909399;
      font-size: 14px;
    }
  }
}
</style>
