<template>
  <div class="deviceCortrol">
    <!--    <baseSection name="今日设备报警">-->
    <!--      <div class="TopAlarm">-->
    <!--        <div class="todayAlarm">-->
    <!--          <div class="nowAlarm">-->
    <!--            <div class="icon">-->
    <!--              <img src="./icon/sbgl_bjjl_icon_bj.svg" alt="" />-->
    <!--            </div>-->
    <!--            <div class="text">-->
    <!--              <div class="number">{{ LogData.alarmName ? '正在报警' : '无报警' }}</div>-->
    <!--              <div class="warning">{{ LogData.alarmName ? LogData.alarmName : '' }}</div>-->
    <!--              <div class="info">{{ LogData.updatedTime ? LogData.updatedTime : '无日期' }}</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="sumAlarm">-->
    <!--            <div class="icon">-->
    <!--              <img src="./icon/sbgl_bjjl_icon_cs.svg" alt="" />-->
    <!--            </div>-->
    <!--            <div class="text">-->
    <!--              <div class="number">{{ LogData.totalNumber ? LogData.totalNumber : '0' }}</div>-->
    <!--              <div class="info">总次数</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="timeAlarm">-->
    <!--            <div class="icon">-->
    <!--              <img src="./icon/sbgl_bjjl_icon_fz.svg" alt="" />-->
    <!--            </div>-->
    <!--            <div class="text">-->
    <!--              <div class="number">-->
    <!--                {{ Math.floor(LogData.totalContinueTime / 1000 / 60 / 60).toFixed(0) }}-->
    <!--                <span class="timerinfo">小时</span>-->
    <!--                {{ ((LogData.totalContinueTime / 1000 / 60) % 60).toFixed(0) }}-->
    <!--                <span class="timerinfo">分钟</span>-->
    <!--              </div>-->
    <!--              <div class="info">总时长</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </baseSection>-->
    <!-- main -->
    <baseSection name="报警分析">
      <!-- main -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-button :type="isTimer ? 'warning' : ''" :autofocus="true" @click="timer">按时间最新</el-button>
          <el-button :type="isSum ? 'warning' : ''" @click="sum">按报警次数最多</el-button>
          <el-button :type="isSustainTimer ? 'warning' : ''" @click="sustainTimer">按持续时间最长</el-button>
        </el-col>
        <el-col v-if="type === 0" :offset="12" :span="2">
          <el-button class="export iconfont icon-dcdaochu1" @click="exportAlarmFile">导出</el-button>
        </el-col>
        <el-col v-if="type === 0" :span="3" style="transform: translateX(-20px)">
          <el-date-picker :picker-options="timeChange" v-model="date" @change="handleDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%; margin-top: 10px" height="650px" v-if="tableData.length">
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="alarmName" label="报警内容"> </el-table-column>
        <el-table-column prop="alarmCode" label="报警编号"> </el-table-column>
        <el-table-column v-if="type === 0" prop="status" label="状态"> </el-table-column>
        <el-table-column
          :prop="type === 0 ? 'beginTime' : type === 1 ? 'totalNumber' : 'totalContinueTime'"
          :label="type === 0 ? '报警开始时间' : type === 1 ? '报警次数' : '持续时长'"
        >
        </el-table-column>
        <el-table-column v-if="type === 0" prop="endTime" label="报警结束时间"> </el-table-column>
      </el-table>
      <el-row v-else>
        <el-col :offset="10" :span="4"><span class="noneText">暂无数据</span></el-col>
      </el-row>
      <el-col :offset="15" :span="9" style="margin-top: 10px" v-if="tableData.length">
        <el-pagination
          v-if="type === 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageOptions.currentPage"
          :page-sizes="[20, 40, 60, 80, 100]"
          :page-size="pageOptions.currentPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageOptions.total"
        >
        </el-pagination>
      </el-col>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
export default {
  name: 'deviceOEE',
  data() {
    return {
      deviceId: '',
      LogData: {},
      tableData: [],
      type: 0,
      isTimer: true,
      isSum: false,
      isSustainTimer: false,
      pageOptions: {
        total: 0,
        currentPage: 1,
        currentPageSize: 20
      },
      settimer: null,
      date: '',
      timeChange: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      }
    };
  },
  components: { baseSection },
  created() {
    this.init();
  },
  beforeDestroy() {
    clearTimeout(this.settimer);
    this.settimer = null;
  },
  methods: {
    async init() {
      this.isTimer = true;
      this.isSum = false;
      this.isSustainTimer = false;
      this.deviceId = this.$parent.deviceId;
      const LogRes = await this.$apis.Alarm.Log({ deviceId: this.deviceId });
      this.LogData = LogRes;
      // 时间戳格式化
      if (this.LogData.updatedTime) {
        this.LogData.updatedTime = new Date(this.LogData.updatedTime);
        this.LogData.updatedTime = `${this.LogData.updatedTime.toLocaleDateString().replace(/\//g, '-')} ${this.LogData.updatedTime.toTimeString().substr(0, 8)}`;
      }
      const TableRes = await this.$apis.Alarm.TimeList({ deviceId: this.deviceId });
      this.tableData = TableRes.items;
      this.tableData.forEach(item => {
        item.status === 0 ? (item.status = '正在报警') : (item.status = '报警结束');
        item.beginTime = item.beginTime.substr(0, 19);
      });
      this.pageOptions = {
        total: TableRes.total,
        currentPage: TableRes.currentPage,
        currentPageSize: TableRes.currentPageSize
      };
      this.settimer = setTimeout(async () => {
        this.deviceId = this.$parent.deviceId;
        const LogRes = await this.$apis.Alarm.Log({ deviceId: this.deviceId });
        this.LogData = LogRes;
        // 时间戳格式化
        if (this.LogData.updatedTime) {
          this.LogData.updatedTime = new Date(this.LogData.updatedTime);
          this.LogData.updatedTime = `${this.LogData.updatedTime.toLocaleDateString().replace(/\//g, '-')} ${this.LogData.updatedTime.toTimeString().substr(0, 8)}`;
        }
      }, 5000);
    },
    async exportAlarmFile() {
      let data = { deviceId: this.deviceId };
      if (this.date) {
        data.beginTime = `${this.date} 00:00:00`;
        data.endTime = `${this.date} 23:59:59`;
      }
      const res = await this.$apis.Alarm.exportValidAlarmLog(data);
      if (res) {
        this.$apis.Alarm.exportAlarm(data);
      }
    },
    async handleDate() {
      this.isTimer = true;
      this.isSum = false;
      this.isSustainTimer = false;
      let TableRes;
      if (this.date) {
        TableRes = await this.$apis.Alarm.TimeList({ deviceId: this.deviceId, beginTime: `${this.date} 00:00:00`, endTime: `${this.date} 23:59:59` });
      } else {
        TableRes = await this.$apis.Alarm.TimeList({ deviceId: this.deviceId });
      }
      this.tableData = TableRes.items;
      this.tableData.forEach(item => {
        item.status === 0 ? (item.status = '正在报警') : (item.status = '报警结束');
        item.beginTime = item.beginTime.substr(0, 19);
      });
      this.pageOptions = {
        currentPage: TableRes.currentPage,
        currentPageSize: TableRes.currentPageSize,
        total: TableRes.total
      };
      const LogRes = await this.$apis.Alarm.Log({ deviceId: this.deviceId });
      this.LogData = LogRes;
      // 时间戳格式化
      if (this.LogData.updatedTime) {
        this.LogData.updatedTime = new Date(this.LogData.updatedTime);
        this.LogData.updatedTime = `${this.LogData.updatedTime.toLocaleDateString().replace(/\//g, '-')} ${this.LogData.updatedTime.toTimeString().substr(0, 8)}`;
      }
    },
    async timer() {
      this.isTimer = true;
      this.isSum = false;
      this.isSustainTimer = false;
      this.type = 0;
      let data = { deviceId: this.deviceId, currentPageSize: this.pageOptions.currentPageSize, currentPage: this.pageOptions.currentPage };
      if (this.date) {
        data.beginTime = `${this.date} 00:00:00`;
        data.endTime = `${this.date} 23:59:59`;
      }
      const TableRes = await this.$apis.Alarm.TimeList(data);
      this.tableData = TableRes.items;
      this.tableData.forEach(item => {
        item.status === 0 ? (item.status = '正在报警') : (item.status = '报警结束');
        item.beginTime = item.beginTime.substr(0, 19);
      });
      this.pageOptions = {
        currentPage: TableRes.currentPage,
        currentPageSize: TableRes.currentPageSize,
        total: TableRes.total
      };
      const LogRes = await this.$apis.Alarm.Log({ deviceId: this.deviceId });
      this.LogData = LogRes;
      // 时间戳格式化
      if (this.LogData.updatedTime) {
        this.LogData.updatedTime = new Date(this.LogData.updatedTime);
        this.LogData.updatedTime = `${this.LogData.updatedTime.toLocaleDateString().replace(/\//g, '-')} ${this.LogData.updatedTime.toTimeString().substr(0, 8)}`;
      }
    },
    async sum() {
      this.isTimer = false;
      this.isSum = true;
      this.isSustainTimer = false;
      this.type = 1;
      const TableRes = await this.$apis.Alarm.SumList({ deviceId: this.deviceId });
      this.tableData = TableRes;
      this.tableData.forEach(item => {
        item.status === 0 ? (item.status = '正在报警') : (item.status = '报警结束');
      });
      const LogRes = await this.$apis.Alarm.Log({ deviceId: this.deviceId });
      this.LogData = LogRes;
      // 时间戳格式化
      if (this.LogData.updatedTime) {
        this.LogData.updatedTime = new Date(this.LogData.updatedTime);
        this.LogData.updatedTime = `${this.LogData.updatedTime.toLocaleDateString().replace(/\//g, '-')} ${this.LogData.updatedTime.toTimeString().substr(0, 8)}`;
      }
    },
    async sustainTimer() {
      this.isTimer = false;
      this.isSum = false;
      this.isSustainTimer = true;
      this.type = 2;
      const TableRes = await this.$apis.Alarm.sustainTimerList({ deviceId: this.deviceId });
      this.tableData = TableRes;
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].totalContinueTime = `${Math.floor(this.tableData[i].totalContinueTime / 1000 / 60 / 60)}小时${(
          (this.tableData[i].totalContinueTime / 1000 / 60) %
          60
        ).toFixed(0)}分钟`;
      }
      this.tableData.forEach(item => {
        item.status === 0 ? (item.status = '正在报警') : (item.status = '报警结束');
      });
      const LogRes = await this.$apis.Alarm.Log({ deviceId: this.deviceId });
      this.LogData = LogRes;
      // 时间戳格式化
      if (this.LogData.updatedTime) {
        this.LogData.updatedTime = new Date(this.LogData.updatedTime);
        this.LogData.updatedTime = `${this.LogData.updatedTime.toLocaleDateString().replace(/\//g, '-')} ${this.LogData.updatedTime.toTimeString().substr(0, 8)}`;
      }
    },

    async handleSizeChange(i) {
      let data = { deviceId: this.deviceId, currentPageSize: this.pageOptions.currentPageSize, currentPage: this.pageOptions.currentPage };
      if (this.date) {
        data.beginTime = `${this.date} 00:00:00`;
        data.endTime = `${this.date} 23:59:59`;
      }
      this.pageOptions.currentPageSize = i;
      const TableRes = await this.$apis.Alarm.TimeList(data);
      this.tableData = TableRes.items;
      this.tableData.forEach(item => {
        item.status === 0 ? (item.status = '正在报警') : (item.status = '报警结束');
      });
    },
    handleCurrentChange(i) {
      switch (this.type) {
        case 0:
          this.pageOptions.currentPage = i;
          this.timer();
          break;
        case 1:
          this.sum();
          break;
        case 2:
          this.sustainTimer();
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.deviceCortrol {
  margin-top: 20px;
}
.el-button {
  margin-left: 0px;
}
.el-table thead {
  background-color: #f3f3f3;
}
.TopAlarm {
  width: 100%;
  height: 160px;
  padding: 10px;
  box-sizing: border-box;
  .todayAlarm {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;

    .nowAlarm,
    .sumAlarm,
    .timeAlarm {
      width: 390px;
      height: 80px;
      display: flex;
      align-items: center;
      padding: 20px;
    }
    .icon {
      width: 80px;
      height: 80px;
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 80px;
      margin-left: 15px;
      .warning {
        color: #ea3837;
        font-size: 16px;
      }
      .info {
        color: #a3a3a3;
      }
      .number {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
/deep/.export {
  width: 90px;
  height: 36px;
}
.el-button--warning {
  color: #000;
  border: 1px solid #f39800;
  color: #f39800;
  background-color: #fff;
}
.noneText {
  display: block;
  margin-top: 100px;
  color: #a3a3a3;
}
.text .timerinfo {
  font-size: 14px;
  font-weight: 400px;
  color: #a3a3a3;
}
</style>
