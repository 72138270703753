<template>
  <div class="deviceCortrol">
    <baseSection name="OEE计算范围" style="margin-bottom: 40px">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="平均OEE计算">
          <el-switch v-model="form.averageOee" @change="changeSwitch"></el-switch>
        </el-form-item>
      </el-form>
      <span class="hint">开启后，表明该设备参与OEE设备效率的计算范围</span>
    </baseSection>
    <!-- title -->
    <baseSection name="设备参数设置">
      <el-row>
        <el-col :span="6" style="margin-bottom: 30px">
          <el-button v-if="isParamsEdit" type="primary" class="iconfont icon-dcicon_save1" @click="saveParams()">保存</el-button>
          <el-button v-if="!isParamsEdit" class="iconfont icon-dca-bianji1" @click="isParamsEdit = true">编辑</el-button>
          <el-button v-if="isParamsEdit" class="iconfont icon-dcx" @click="quitParamsEdit()">取消</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-form ref="form" label-width="100px">
          <el-row>
            <el-col :offset="0" :span="7" v-for="(item, index) in paramsList" :key="item.id">
              <el-form-item class="form_item" :label="`生产参数${index + 1}`" :rules="{ required: true, message: '请输入停机原因', trigger: 'blur' }">
                <el-input v-model="item.name" :disabled="item.isRealtimeValue || !isParamsEdit"></el-input>
                <el-button v-if="!item.isRealtimeValue && isParamsEdit" @click="del(index)" class="delParams iconfont icon-dcshanchu" type="text"></el-button>
                <el-button v-if="index === paramsList.length - 1 && isParamsEdit" @click="add()" type="text" class="addParams iconfont icon-dcsquare_add"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </baseSection>
    <!-- main -->
    <baseSection name="设置此设备生产效率">
      <el-row>
        <el-col :span="6">
          <el-button v-if="isTemplateEdit" type="primary" class="iconfont icon-dcicon_save1" @click="saveTemplate()">保存</el-button>
          <el-button v-if="!isTemplateEdit" class="iconfont icon-dca-bianji1" @click="isTemplateEdit = true">编辑</el-button>
          <el-button v-if="isTemplateEdit" class="iconfont icon-dcx" @click="quitTemplateEdit()">取消</el-button>
        </el-col>
      </el-row>
      <div class="OeeconfigTemplate">
        <div class="availability">
          <div class="TemplateText">可用率</div>
          <div class="Template" @click.stop="OpenTemplate(1)">{{ Template.calcAvailabilityRule || '请输入' }}</div>
        </div>
        <div class="manifestation">
          <div class="TemplateText">表现指数</div>
          <div class="Template" @click.stop="OpenTemplate(2)">{{ Template.calcPerformanceRule || '请输入' }}</div>
        </div>
        <div class="quality">
          <div class="TemplateText">质量指数</div>
          <div class="Template" @click.stop="OpenTemplate(3)">{{ Template.calcQualityRule || '请输入' }}</div>
        </div>
      </div>
      <!-- main -->
      <Template @getarith="getarith" :arr="arr" :str="str" :paramsList="paramsList" v-if="dialogVisible" :dialogVisible="dialogVisible" @close="dialogVisible = false" />
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Template from './template.vue';
export default {
  data() {
    return {
      paramsList: [],
      deviceId: this.$parent.deviceId,
      dialogVisible: false,
      // 设备生产效率展示值
      Template: {
        deviceId: this.$parent.deviceId,
        calcAvailabilityRule: '',
        calcPerformanceRule: '',
        calcQualityRule: ''
      },
      // 设备生产效率数组值
      calcAvailabilityRuleArr: [],
      calcPerformanceRuleArr: [],
      calcQualityRuleArr: [],
      // 打开计算公式dialog时：可用指数：1  质量指数：2  表现指数：3
      type: 0,
      // 点击后当前项的数据
      // 数组用户删除。字符用于展示
      arr: [],
      str: '',
      // 是否开启平均OEE
      form: {
        averageOee: false
      },
      // 是否开启设备参数设置
      isParamsEdit: false,
      // 是否开启设备生产效率设置
      isTemplateEdit: false
    };
  },
  mounted() {
    this.init();
  },
  components: {
    Template,
    baseSection
  },
  methods: {
    async init() {
      // 设备参数list
      const paramsList = await this.$apis.Oeeconfig.getName({ deviceId: this.deviceId });
      this.paramsList = paramsList;
      const deviceId = this.deviceId;
      // 是否开启OEE
      const { isOee } = (await this.$apis.device.getDeviceInfo({ deviceId })).data;
      this.form.averageOee = isOee ? true : false;
      // 计算公式获取
      const template = await this.$apis.Oeeconfig.getCalcTemplate({ deviceId: this.deviceId });
      this.Template = template;
      // 还原数据
      this.calcAvailabilityRuleArr = JSON.parse(this.Template.calcAvailabilityRuleRaw);
      this.calcPerformanceRuleArr = JSON.parse(this.Template.calcPerformanceRuleRaw);
      this.calcQualityRuleArr = JSON.parse(this.Template.calcQualityRuleRaw);
      // 原展示数据清空
      this.Template.calcAvailabilityRule = '';
      this.Template.calcPerformanceRule = '';
      this.Template.calcQualityRule = '';
      // 重新赋值展示数据
      if (this.calcAvailabilityRuleArr) {
        this.calcAvailabilityRuleArr.forEach(item => {
          this.Template.calcAvailabilityRule += item.name;
        });
      }
      if (this.calcPerformanceRuleArr) {
        this.calcPerformanceRuleArr.forEach(item => {
          this.Template.calcPerformanceRule += item.name;
        });
      }
      if (this.calcQualityRuleArr) {
        this.calcQualityRuleArr.forEach(item => {
          this.Template.calcQualityRule += item.name;
        });
      }
    },
    async changeSwitch(i) {
      let isOee = i ? 1 : 0;
      let data = {
        isOee,
        deviceId: this.deviceId
      };
      await this.$apis.deviceOee.updateOeeStatus(data);
    },
    del(i) {
      this.paramsList.splice(i, 1);
    },
    add() {
      this.paramsList.push({ name: '', deviceId: this.deviceId });
    },
    async quitParamsEdit() {
      this.isParamsEdit = false;
      const paramsList = await this.$apis.Oeeconfig.getName({ deviceId: this.deviceId });
      this.paramsList = paramsList;
    },
    async quitTemplateEdit() {
      this.isTemplateEdit = false;
      const template = await this.$apis.Oeeconfig.getCalcTemplate({ deviceId: this.deviceId });
      this.Template = template;
    },
    async saveParams() {
      if (this.paramsList.length === 0) {
        const { fail } = await this.$apis.Oeeconfig.del({ deviceId: this.$parent.deviceId });
        if (fail) {
          return false;
        }
      } else {
        let data = [];
        let isnull = false;
        this.paramsList.forEach(item => {
          if (!item.name.trim()) {
            this.$message.warning('有未填写的生产参数');
            isnull = true;
          }
          if (!item.isRealtimeValue) {
            data.push(item);
          }
        });
        if (isnull) {
          return false;
        }
        const { fail } = await this.$apis.Oeeconfig.saveName({ data, deviceId: this.deviceId });
        if (fail) {
          return false;
        }
      }
      this.isParamsEdit = false;
    },
    OpenTemplate(i) {
      if (!this.isTemplateEdit) {
        return;
      }
      switch (i) {
        case 1:
          this.arr = this.calcAvailabilityRuleArr;
          this.str = this.Template.calcAvailabilityRule;
          break;
        case 2:
          this.arr = this.calcPerformanceRuleArr;
          this.str = this.Template.calcPerformanceRule;
          break;
        case 3:
          this.arr = this.calcQualityRuleArr;
          this.str = this.Template.calcQualityRule;
          break;
      }
      this.type = i;
      this.dialogVisible = true;
    },
    getarith(Arr, Str) {
      switch (this.type) {
        case 1:
          this.calcAvailabilityRuleArr = Arr;
          this.Template.calcAvailabilityRule = Str;
          break;
        case 2:
          this.calcPerformanceRuleArr = Arr;
          this.Template.calcPerformanceRule = Str;
          break;
        case 3:
          this.calcQualityRuleArr = Arr;
          this.Template.calcQualityRule = Str;
          break;
      }
    },
    async saveTemplate() {
      // 可用性
      let calcAvailabilityRule = '';
      if (this.calcAvailabilityRuleArr) {
        this.calcAvailabilityRuleArr.forEach(item => {
          if (item.Type === 0) {
            calcAvailabilityRule = calcAvailabilityRule + item.name;
          } else {
            calcAvailabilityRule = `${calcAvailabilityRule}params_${item.name}`;
          }
        });
      }

      let calcPerformanceRule = '';
      if (this.calcPerformanceRuleArr) {
        this.calcPerformanceRuleArr.forEach(item => {
          if (item.Type === 0) {
            calcPerformanceRule = calcPerformanceRule + item.name;
          } else {
            calcPerformanceRule = `${calcPerformanceRule}params_${item.name}`;
          }
        });
      }

      let calcQualityRule = '';
      if (this.calcQualityRuleArr) {
        this.calcQualityRuleArr.forEach(item => {
          if (item.Type === 0) {
            calcQualityRule = calcQualityRule + item.name;
          } else {
            calcQualityRule = `${calcQualityRule}params_${item.name}`;
          }
        });
      }
      const data = {
        deviceId: this.deviceId,
        calcAvailabilityRule,
        calcPerformanceRule,
        calcQualityRule,
        // JSON数据
        calcAvailabilityRuleRaw: JSON.stringify(this.calcAvailabilityRuleArr),
        calcPerformanceRuleRaw: JSON.stringify(this.calcPerformanceRuleArr),
        calcQualityRuleRaw: JSON.stringify(this.calcQualityRuleArr)
      };
      const { fail } = await this.$apis.Oeeconfig.saveCalcTemplate(data);
      if (fail) {
        return false;
      }
      this.isTemplateEdit = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.deviceCortrol {
  margin-top: 20px;
}
.el-icon-warning:before {
  font-size: 18px;
}
.hint_text {
  color: #000;
  font-size: 14px;
  margin: 0 10px;
}
.hint {
  padding-left: 100px;
  color: #909399;
}
/deep/.el-input__inner {
  width: 320px;
}
.form_item {
  position: relative;
  width: 540px;
  margin-bottom: 40px;
  .delParams {
    position: absolute;
    right: 90px;
  }
  .addParams {
    position: absolute;
    right: 60px;
  }
}
.OeeconfigTemplate {
  display: flex;
  justify-content: space-between;
  height: 200px;
  padding: 20px 0;
  .availability,
  .manifestation,
  .quality {
    border-radius: 5px;
    width: 33%;
    border: 1px solid #e2dcdc;
    padding: 10px 0px;
    .TemplateText {
      height: 20%;
      font-size: 16px;
      padding-left: 20px;
    }
    .Template {
      margin: 0px 20px;
      height: 50px;
      padding-left: 20px;
      line-height: 50px;
      color: #9f9fa1;
      background-color: #f3f3f5;
      cursor: pointer;
    }
  }
}
.el-button--text {
  color: #121212;
}
.el-button--text:hover {
  color: #f39800;
}
</style>
