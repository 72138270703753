<template>
  <div class="piecharts"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      myChart: null,
      piedata: null
    };
  },
  mounted() {
    this.init();
  },
  props: {
    data: {
      type: Object,
      require: true
    }
  },
  watch: {
    data() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      this.piedata = this.$props.data;
      let chartDom = document.querySelector('.piecharts');
      this.myChart = echarts.init(chartDom);
      let option;

      option = {
        legend: {
          top: 'bottom'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {d}%'
        },
        series: [
          {
            name: '运行记录饼图',
            type: 'pie',
            radius: [0, 90],
            center: ['50%', '50%'],
            // roseType: 'area',
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false
            },
            data: [
              {
                value: Math.ceil(this.piedata.totalRunningTimeRate * 100),
                name: '运行时长',
                itemStyle: {
                  color: '#19a572'
                }
              },
              {
                value: Math.ceil(this.piedata.totalFreeTimeRate * 100),
                name: '空闲时长',
                itemStyle: {
                  color: '#f5a623'
                }
              },
              {
                value: Math.ceil(this.piedata.totalAlarmTimeRate * 100),
                name: '故障时长',
                itemStyle: {
                  color: '#ea3837'
                }
              },
              {
                value: Math.ceil(this.piedata.totalOfflineTimeRate * 100),
                name: '离线时长',
                itemStyle: {
                  color: '#dcdcdc'
                }
              }
            ]
          }
        ]
      };
      this.myChart.clear();
      option && this.myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.piecharts {
  width: 100%;
  height: 100%;
}
</style>
