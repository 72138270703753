<template>
  <div class="HistoryDevice">
    <el-row>
      <el-col :offset="13" :span="11">
        <!-- <el-button type="text" :class="isWeek ? '' : 'textNOactive'" @click="getweek">7日</el-button>
        <el-button type="text" :class="isWeek ? 'textNOactive' : ''" class="month" @click="getmonth">30日</el-button> -->
        <el-radio-group v-model="TimeFormat" @change="changgeradio">
          <el-radio-button label="7日" @click="getweek"></el-radio-button>
          <el-radio-button label="30日" @click="getmonth"></el-radio-button>
        </el-radio-group>
        <Timerpick :vModel="date" startPlaceholder="开始时间" endPlaceholder="结束时间" rangeSeparator="-至-" :clearable="false" :editable="false" type="date" format="yyyy-MM-dd" />
        <el-button class="searchBtn" @click="search">查询</el-button>
      </el-col>
    </el-row>
    <div class="historcharts"></div>
  </div>
</template>

<script>
import Timerpick from '@/components/TimerPick/index.vue';
import * as echarts from 'echarts';
export default {
  components: {
    Timerpick
  },
  props: ['deviceId'],
  data() {
    return {
      date: [],
      lineData: null,
      timer: [],
      qualityList: [],
      performanceList: [],
      availabilityList: [],
      oeeList: [],
      isSearch: false,
      isWeek: true,
      TimeFormat: '7日'
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    search() {
      this.isSearch = true;
      this.init();
    },
    changgeradio(label) {
      switch (label) {
        case '7日':
          this.getweek();
          break;
        case '30日':
          this.getmonth();
          break;
      }
    },
    getweek() {
      this.isWeek = true;
      let days = [];
      let DateD = new Date();
      for (let i = 0; i <= 144; i += 24) {
        let dateItem = new Date(DateD.getTime() - i * 60 * 60 * 1000);
        let y = dateItem.getFullYear();
        let m = dateItem.getMonth() + 1;
        let d = dateItem.getDate();
        let valueItem = `${y}-${m}-${d}`;
        days.push(valueItem);
      }
      this.date[0] = days[days.length - 1];
      this.date[1] = days[0];
      this.isSearch = true;
      this.init();
    },
    getmonth() {
      this.isWeek = false;
      let ago = new Date();
      let now = new Date(ago);
      now.setDate(ago.getDate() - 29);
      let agoDay = `${now.getFullYear()}-${now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1}-${now.getDate()}`;
      let nowDay = `${ago.getFullYear()}-${ago.getMonth() + 1 < 10 ? `0${ago.getMonth() + 1}` : ago.getMonth() + 1}-${ago.getDate()}`;
      this.date[0] = agoDay;
      this.date[1] = nowDay;
      this.isSearch = true;
      this.init();
    },
    async init() {
      let res;
      if (this.isSearch) {
        const data = {
          beginTime: this.date.length ? `${this.date[0]} 00:00:00` : '',
          endTime: this.date.length ? `${this.date[1]} 00:00:00` : ''
        };
        if (this.date.length) {
          res = await this.$apis.deviceOee.deviceOeeHistory({ deviceId: this.deviceId, ...data });
        } else {
          res = await this.$apis.deviceOee.deviceOeeHistory({ deviceId: this.deviceId });
        }
      } else {
        res = await this.$apis.deviceOee.deviceOeeHistory({ deviceId: this.deviceId });
      }
      this.lineData = res;
      this.timer = [];
      this.performanceList = [];
      this.availabilityList = [];
      this.oeeList = [];
      this.qualityList = [];
      this.lineData.indexList.forEach(item => {
        this.timer.push(item);
      });
      this.lineData.performanceList.forEach(item => {
        item = `${item * 100}`;
        this.performanceList.push(item);
      });
      this.lineData.availabilityList.forEach(item => {
        item = `${item * 100}`;
        this.availabilityList.push(item);
      });
      this.lineData.oeeList.forEach(item => {
        item = `${item * 100}`;
        this.oeeList.push(item);
      });
      this.lineData.qualityList.forEach(item => {
        item = `${item * 100}`;
        this.qualityList.push(item);
      });
      let chartDom = document.querySelector('.historcharts');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${((item.value * 100) / 100).toFixed(2)}%<br/>`);
            });
            return str;
          }
        },
        legend: {
          top: 'bottom',
          left: '90',
          data: ['可用指数', '质量指数', '表现指数', 'OEE'],
          itemWidth: 17,
          itemHeight: 6
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '16%',
          top: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timer
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '可用指数',
            type: 'line',
            data: this.availabilityList,
            itemStyle: {
              color: '#19A572'
            },
            axisLabel: {
              formatter: '{value} %'
            }
          },
          {
            name: '质量指数',
            type: 'line',
            data: this.qualityList,
            itemStyle: {
              color: '#F39800'
            },
            axisLabel: {
              formatter: '{value} %'
            }
          },
          {
            name: '表现指数',
            type: 'line',
            data: this.performanceList,
            itemStyle: {
              color: '#EA3837'
            },
            axisLabel: {
              formatter: '{value} %'
            }
          },
          {
            name: 'OEE',
            type: 'line',
            data: this.oeeList,
            itemStyle: {
              color: '#5A5A5A'
            },
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped lang="scss">
.HistoryDevice {
  width: 100%;
  height: 350px;
}
.el-row {
  margin-bottom: 30px;
}
.historcharts {
  width: 100%;
  height: 90%;
}
.month {
  margin-right: 10px;
}
.searchBtn {
  padding: 10px 20px;
  position: relative;
  margin-left: 3px;
}
.textNOactive {
  color: #afaaaa;
}
/deep/ .el-radio-group,
.el-radio-button,
.el-radio-button__inner {
  height: 36px;
}
/deep/ .el-radio-button__inner {
  padding: 10px 20px;
}
.el-radio-group {
  margin-right: 10px;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  padding: 10px 20px;
  border-radius: 4px 4px 4px 4px;
  background: #fff;
  color: #f39800;
  // border: 1px solid #f39800;
}
</style>
