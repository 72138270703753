<template>
  <div class="AlarmConfig">
    <el-row>
      <el-button style="margin-bottom: 20px" icon="el-icon-plus" @click="addRule">新增规则</el-button>
    </el-row>
    <Basetable :multi="false" :data="tableData" :columns="columns" :operations="operations" />
    <el-col :offset="16" :span="8">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageOptions.currentPage"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="pageOptions.currentPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageOptions.total"
      >
      </el-pagination>
    </el-col>
  </div>
</template>

<script>
import Basetable from '@/components/table/base-table/base-table';
import { TABLE } from '/app.config';

const { edit, del } = TABLE.icons;
export default {
  data() {
    return {
      deviceId: this.$parent.deviceId,
      tableData: [],
      pageOptions: {
        total: 0,
        currentPage: 1,
        currentPageSize: 20
      },
      columns: [
        {
          prop: 'code',
          label: '报警编号',
          href: true,
          handle: this.into
        },
        {
          prop: 'name',
          label: '报警内容'
        },
        {
          prop: 'alarmstatus',
          label: '状态'
        },
        {
          prop: 'createdTime',
          label: '注册时间'
        }
      ],
      operations: {
        width: 200,
        handles: [
          {
            icon: edit,
            name: '编辑',
            handle: this.edit
          },
          {
            icon: del,
            name: '删除',
            handle: this.del
          }
        ]
      }
    };
  },
  components: {
    Basetable
  },
  created() {
    this.init();
  },
  methods: {
    into(data) {
      this.$router.push({ path: '/deviceInfo/detail/alarmRulesDetail', query: { data: { ...data, deviceId: this.deviceId } } });
    },
    edit(data) {
      this.$router.push({ path: '/deviceInfo/detail/alarmAddRules', query: { data: { ...data, deviceId: this.deviceId } } });
    },
    del(data) {
      this.$confirm('确认要删除该报警参数吗?', '删除报警参数', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.AlarmConfig.del({ alarmId: data.alarmId });
        this.init();
        if (fail) {
          return false;
        }
      });
    },
    addRule() {
      this.$router.push({ path: '/deviceInfo/detail/alarmAddRules', query: { data: this.deviceId } });
    },
    async init() {
      const deviceId = this.deviceId;
      const { currentPage, currentPageSize } = this.pageOptions;
      const data = {
        deviceId,
        currentPageSize,
        currentPage
      };
      const res = await this.$apis.AlarmConfig.alarmlist(data);
      this.pageOptions = {
        currentPageSize: res.currentPageSize,
        currentPage: res.currentPage,
        total: res.total
      };
      this.tableData = res.items;
      this.tableData.forEach(item => {
        if (item.status) {
          item.alarmstatus = '启用';
        } else {
          item.alarmstatus = '停用';
        }
      });
    },
    handleSizeChange(i) {
      this.pageOptions.currentPageSize = i;
      this.init();
    },
    handleCurrentChange(i) {
      this.pageOptions.currentPage = i;
      this.init();
    }
  }
};
</script>
<style lang="scss" scoped>
.AlarmConfig {
  padding: 10px 0;
}
</style>
