<template>
  <div class="EnterParams">
    <el-form ref="form" :model="form" label-width="120px">
      <el-row>
        <el-col :offset="3" :span="7">
          <el-form-item label="设置生产日期:">
            <el-date-picker :picker-options="timeChange" v-model="form.date" @change="handleDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paramstable.length">
        <el-col :offset="3" :span="7"><span class="sepText">配置相关参数</span></el-col>
      </el-row>
      <el-row>
        <el-col v-for="item in paramstable" :key="item.paramNameId" :offset="3" :span="7">
          <el-form-item :label="`${item.paramName}:`">
            <el-input v-model="item.paramValue"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :offset="18" :span="7">
        <el-button class="iconfont icon-dcx" @click="$emit('close')">取消</el-button>
        <el-button icon="el-icon-check" type="primary" @click="save">保存</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: ''
      },
      deviceId: '',
      timeChange: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      },
      paramstable: []
    };
  },
  created() {
    this.deviceId = this.$parent.$parent.deviceId;
  },
  methods: {
    async handleDate() {
      const date = this.form.date;
      const deviceId = this.deviceId;
      const data = {
        date,
        deviceId
      };
      const ListRes = await this.$apis.deviceOee.EnterParamsList(data);
      this.paramstable = ListRes;
    },
    async save() {
      const deviceId = this.deviceId;
      const date = this.form.date;
      const data = {
        deviceId,
        date,
        params: this.paramstable
      };
      const { fail } = await this.$apis.deviceOee.EnterParamsSave(data);
      this.$emit('close');
      if (fail) {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.sepText {
  border-bottom: 3px solid #f5ad33;
}
</style>
