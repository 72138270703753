<template>
  <div class="deviceCortrol">
    <!-- title -->
    <baseSection name="参数概况" v-if="topParams.length">
      <!-- main -->
      <div class="paramsInfo" v-if="topParams.length">
        <div class="paramsInfoBox" v-for="item in topParams" :key="item.tag">
          <div class="paramsName">
            <span class="parname">{{ item.paramName }}</span>
            <div class="unittype">{{ item.unit }}</div>
          </div>
          <div class="paramsData" :title="item.data">{{ item.data }}</div>
        </div>
      </div>
    </baseSection>
    <!-- title -->
    <!-- <baseSection name="产量分析"> </baseSection> -->
    <baseSection name="趋势分析">
      <div class="chartArr">
        <Chart :chartData="item" v-for="item in chartParams" :key="item.tag" />
      </div>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import * as echarts from 'echarts';
import Chart from './chart.vue';
export default {
  name: 'deviceCortrol',
  data() {
    return {
      deviceId: '',
      topParams: [],
      chartParams: [],
      timer: null,
      tags: []
    };
  },
  components: {
    Chart,
    baseSection
  },
  created() {
    this.deviceId = this.$parent.deviceId;
    this.init();
    this.timer = setInterval(() => {
      // if (this.testTop.length) {
      this.update();
      // }
    }, 5000);
  },
  mounted() {
    echarts.connect('group1');
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    async init() {
      const deviceId = this.deviceId;
      const ParamsNameRes = await this.$apis.device.getParamsName({ deviceId });
      ParamsNameRes.forEach((item, index) => {
        if (item.isTop) {
          this.topParams.push({ ...item, index: index + 1, time: '', data: '' });
        } else {
          this.chartParams.push({ ...item, index: index + 1, time: [], data: [] });
        }
      });
      // 拼接Tag
      this.tags = [];
      ParamsNameRes.forEach(item => {
        this.tags.push(item.tag);
      });
      this.tags = this.tags.toString();
      const ParamsDataRes = await this.$apis.device.getParamsData({ tags: this.tags, deviceId });
      // 筛选上层数据

      this.topParams.forEach(item => {
        if (!ParamsDataRes.length) {
          return false;
        }
        item.time = ParamsDataRes[ParamsDataRes.length - 1][0];
        item.data = ParamsDataRes[ParamsDataRes.length - 1][item.index];
      });
    },
    async update() {
      const deviceId = this.deviceId;
      const ParamsDataRes = await this.$apis.device.getParamsData({ tags: this.tags, deviceId });
      // 筛选上层数据
      this.topParams.forEach(item => {
        if (ParamsDataRes.length) {
          item.time = ParamsDataRes[ParamsDataRes.length - 1][0];
          item.data = ParamsDataRes[ParamsDataRes.length - 1][item.index];
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.deviceCortrol {
  margin-top: 10px;
}
.chartArr {
  margin-top: 10px;
}
.paramsInfo {
  display: flex;
  overflow-x: auto;
  height: 140px;
  padding: 20px 0;
  .paramsInfoBox {
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    width: 24%;
    height: 80px;
    margin: 0px 8px;
    border: 1px solid #efefef;
    background-color: #fafafa;
    .paramsData {
      position: absolute;
      bottom: 15px;
      width: 200px;
      margin-left: 20px;
      height: 40px;
      right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 26px;
      color: #121212;
    }
    .paramsName {
      position: absolute;
      top: 15px;
      left: 15px;
      .parname {
        font-size: 20px;
      }
      .unittype {
        font-size: 14px;
        color: #a3a3a3;
      }
    }
  }
}
</style>
